import React, { memo } from 'react'
import { array, bool, func, string } from 'prop-types'

// components
import Chip from '../Chip'

// styles
import styles from './_.module.scss'

const ActiveChips = memo(({ selectedArray, readOnly, size, onChipClick }) => {
  if (!selectedArray.length) return null

  return selectedArray.map(item => (
    <Chip
      key={item.id}
      size={size}
      className={styles.tag}
      readOnly={readOnly || item.readOnly}
      onRemoveClick={() => onChipClick(item)}
    >
      {item.value}
    </Chip>
  ))
})

ActiveChips.propTypes = {
  size: string.isRequired,
  selectedArray: array,
  readOnly: bool,
  onChipClick: func
}

ActiveChips.defaultProps = {
  selectedArray: [],
  readOnly: false,
  onChipClick: () => null
}

export default ActiveChips

// modules
import { Collection, Resource } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'

const NO_INTERNET_ERROR = 'Network Error'

export const handleUpdate = (
  preventPrimaryContactUpdate,
  model,
  oldEmployeeData,
  cb
) => {
  delete model.email
  const modelMap = {
    ...model,
    employmentId: oldEmployeeData.employmentId,
    isPrimary: preventPrimaryContactUpdate || Boolean(model.isPrimary),
    hasReferralAlert: Boolean(model.hasReferralAlert)
  }
  const config = {
    apiVersion: API_VERSION,
    data: modelMap
  }
  const employees = new Resource(REST_API_ENDPOINTS.personnel, API_VERSION)
  employees.putSigned(oldEmployeeData.employmentId, config, cb)
}

export const handleCreate = (apiEndpoint, model, rolesDataSource, cb = () => null) => {
  const { data: roles } = rolesDataSource || { data: [] }
  const role = model['jobTitleId']
    ? roles.find(role => role.id === parseInt(model.jobTitleId))
    : {}
  const modelMap = {
    ...model,
    isPrimaryContact: !!model.isPrimaryContact,
    alertIsEmail: !!model.alertIsEmail,
    jobTitle: role && role.hasOwnProperty('value') ? role.value : '',
    homeNumber: model.homePhone,
    workNumber: model.workPhone,
    cellNumber: model.cellPhone
  }
  const config = {
    apiVersion: API_VERSION
  }
  const employees = new Collection(apiEndpoint, API_VERSION)
  employees.createSigned(modelMap, config, cb)
}

export const handleEditFormErrors = (err, setErrors) => {
  if (err) {
    const { message, response } = err || {}
    const { data, status } = response
    const { errors: list } = data || {}
    const errors = list && list.located
    const mapErrors = mapCreateErrors(errors)
    let networkIssues = 0
    if (status === 403) {
      networkIssues = status
    } else {
      networkIssues = Boolean(Object.keys(mapErrors).length === 0) &&
        message === NO_INTERNET_ERROR
    }
    setErrors(mapErrors)
    return networkIssues
  } else {
    setErrors({})
  }
}

export const handleRemoveEmployee = (employee, cb = () => null) => {
  const employees = new Resource(REST_API_ENDPOINTS.personnel, API_VERSION)
  employees.deleteSigned(employee.employmentId, {}, cb)
}

export const mapCreateErrors = errors => {
  if (!errors || Object.keys(errors).length === 0) return {}
  return {
    ...errors,
    jobTitleId: errors.jobTitle,
    homePhone: errors.homeNumber,
    workPhone: errors.workNumber,
    cellPhone: errors.cellNumber
  }
}

export const sanitizeModel = (schema, employee) => {
  const model = {}
  schema.forEach(item => {
    model[item.name] = employee[item.name] || null
  })
  return model
}

export const validateSchema = (schema, model) => {
  const invalidField = schema.find(field => {
    const fieldValue = model[field.name]

    if (field.isRequired) {
      if (!fieldValue) return true
      if (fieldValue.hasOwnProperty('value') && !fieldValue.value) return true
      if (fieldValue.hasOwnProperty('localNumber') && !fieldValue.localNumber) return true
    }

    if(fieldValue && fieldValue.hasOwnProperty('areaCode') && fieldValue.hasOwnProperty('localNumber')){
      if(fieldValue.hasOwnProperty('isValid')) return !fieldValue.isValid
    }

    return false
  })
  return !invalidField
}
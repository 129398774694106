import React, { useState, memo } from 'react'
import { func, object } from 'prop-types'

// components
import { ToastNotify } from '@compositions/Toast'
import { THEMES } from '@components/constants'
import Modal, { SMALL_MODAL } from '@components/Modal'
import EditControls from '@components/EditControls'
import LoadingMessage from '@components/EditEmployeeForm/components/LoadingMessage'

// modules
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'

const collection = new Collection(REST_API_ENDPOINTS.usersAccount)
const MAX_ATTEMPTS = 3

const ConfirmationModal = memo(({ user, onClose }) => {
  const [tries, setTries] = useState(1)
  const [error, setError] = useState(null)
  const [updatingUser, setUpdatingUser] = useState(false)
  const handleConfirm = () => {
    const loadingMsgId = ToastNotify.info('Sending invitation...')
    setUpdatingUser(true)
    const data = {
      IdEmployee: user.id
    }
    const config = {
      apiVersion: API_VERSION
    }
    collection.createSigned(data, config, (err, response) => {
      setUpdatingUser(false)
      ToastNotify.remove(loadingMsgId)
      if (err) {
        const { response: errResponse } = err || {}
        const { status } = errResponse || {}
        if (status !== 403) {
          let errorMsg = 'There was a problem sending the invitation. Please try again.'
          setError(true)
          if (tries > MAX_ATTEMPTS) {
            onClose()
            errorMsg = (
              <>
                There was a problem sending the invitation.{' '}
                <b>Please contact the administrator</b>
              </>
            )
          }
          setTries(tries + 1)
          ToastNotify.error(errorMsg)
        }
      } else {
        let user = null
        const { data } = response || {}
        const { results } = data || {}
        if (results) {
          user = results
        }
        ToastNotify.success('Invitation sent')
        onClose(user)
      }
    })
  }
  return (
    <Modal
      className="po-r"
      size={SMALL_MODAL}
      footer={exit => {
        return (
          <EditControls
            disabled={updatingUser}
            disabledCancel={updatingUser}
            onCancel={exit}
            okText={error ? 'Retry Confirm' : 'Confirm'}
            okPalette={THEMES.orange}
            onSave={handleConfirm}
          />
        )
      }}
      onToggleActive={() => onClose()}
    >
      {updatingUser ? (
        <LoadingMessage message="Sending invitation..." />
      ) : (
        <div className="di-f fldi-c max-2">
          <h4 className="fowe-b mab-1">
            Please confirm you want to invite this user:
          </h4>
          <p>
            {user.firstName} {user.lastName}
          </p>
          <p>{user.email}</p>
        </div>
      )}
    </Modal>
  )
})

ConfirmationModal.propTypes = {
  user: object,
  onClose: func
}
ConfirmationModal.defaultProps = {
  user: {},
  onClose: () => null
}

export default ConfirmationModal

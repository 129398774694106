import React from 'react'
import { any, array, func, number, object } from 'prop-types'
import cx from 'classnames'
import SyntheticColumnHeader from '../SyntheticColumnHeader'

import { SORT_ASC, SORTBY_NAME } from '../../modules/constants'

const SyntheticColumnHeaderSet = ({
    items,
    onSort: onClick,
    sortColumn,
    sortOrder,
    memoSearch,
    wrapperClassName
}) => {
return (
        <div className={cx('di-f', wrapperClassName)}>
            {items.map((item, index) => (
                <SyntheticColumnHeader
                    key={index}
                    column={item}
                    onClick={onClick}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    memoSearch={memoSearch}
                />
            ))}
        </div>
    )
}

SyntheticColumnHeaderSet.propTypes = {
    items: array,
    sortColumn: number,
    sortOrder: number,
    wrapperClassName: any,
    memoSearch: object,
    onSort: func
}

SyntheticColumnHeaderSet.defaultProps = {
    items: [],
    sortOrder: SORT_ASC,
    sortColumn: SORTBY_NAME,
    wrapperClassName: null,
    memoSearch: {},
    onSort: () => null
}

export default SyntheticColumnHeaderSet
import React, { memo } from 'react'
import { withFormsy } from 'formsy-react'
import { func } from 'prop-types'

// components
import Basic from '../Basic'

const Formsy = memo(({ setValue, getValue, ...props }) => {
  return (
    <Basic
      onChange={(_, checked) => {
        setValue(!checked)
      }}
      checked={getValue()}
      {...props}
    />
  )
})

Formsy.propTypes = {
  getValue: func.isRequired,
  setValue: func.isRequired
}

export default withFormsy(Formsy)

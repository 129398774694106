import React from 'react'
import cx from 'classnames'
import { string } from 'prop-types'

const FieldGroup = ({ className, ...props }) => (
  <div className={cx('di-f', 'fldi-c', 'may-1', className)} {...props} />
)
FieldGroup.propTypes = {
  className: string
}
FieldGroup.defaultProps = {
  className: ''
}

export default FieldGroup

import React from 'react'
import { any, func, bool } from 'prop-types'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

// components
import TextField from '@components/TextField'

function handlePhoneChange(id, phone, onChange, setValue = () => null) {
  const isValid = phone.length <= 1 || phone.length >= 14
  const phoneNumber = parsePhoneNumberFromString(phone, 'US')
  
  const { nationalNumber, country, ext } = phoneNumber || {
    nationalNumber: '',
    country: 'US',
    ext: ''
  }
  const localNumber = nationalNumber.toString()
  const areaCode = localNumber.substr(0, 3)

  onChange({ id, localNumber: localNumber.replace(areaCode, ''), areaCode, countryCode: country, extension: ext, isValid })
}

const PhoneFieldGroup = ({
  defaultValue,
  onChange,
  showHelpText,
  ...props
}) => {
  const { id, areaCode, localNumber, extension } = defaultValue || {}
  const phone = `${areaCode || ''} ${localNumber || ''} x${extension || ''}`
  const phoneNumber = parsePhoneNumberFromString(phone, 'US')

  return (
    <>
      <TextField.CountryPhone
        palette="white"
        value={
          phoneNumber && phoneNumber.hasOwnProperty('nationalNumber')
            ? `${phoneNumber.nationalNumber}${phoneNumber.ext || ''}`
            : ''
        }
        onChange={(event) => {
          event.persist()
          const currentTarget = (event && event.currentTarget) || {}
          handlePhoneChange(id, currentTarget.value || '', onChange)
        }}
        {...props}
      />
      {showHelpText && (
        <span className="fosi-1 co-gray-3">Example: (555) 555-5555 x5555</span>
      )}
    </>
  )
}

PhoneFieldGroup.propTypes = {
  defaultValue: any.isRequired,
  onChange: func.isRequired,
  showHelpText: bool
}

PhoneFieldGroup.defaultProps = {
  showHelpText: true
}

export default PhoneFieldGroup

import React, { memo, useMemo } from 'react'
import cx from 'classnames'
import { string, func, number, array, bool, object } from 'prop-types'

// components
import FlatButton from '@components/FlatButton'
import Checkbox from '@components/Checkbox'
import Action from '../Action'
import PasswordReset from '../PasswordReset'
import PreviewRoles from './components/PreviewRoles'
// modules
import { UTILITIES_ROLE, USER_ROLE } from '@modules/constants'
import Formatter from '@modules/formatter'
import {
  STATUS_ACTIVE,
  STATUS_INVITED,
  STATUS_DISABLED,
  STATUS_NOT_INVITED
} from '../../modules/constants'

import styles from './_.module.scss'

const classes = {
  ...styles,
  wrapper: 'di-f'
}

const actions = {
  [STATUS_ACTIVE]: {
    fontColor: 'co-gray-3',
    iconName: 'disable',
    label: 'Disable',
    cursor: 'cu-p'
  },
  [STATUS_INVITED]: {
    fontColor: 'co-gray-3',
    iconName: 'check',
    label: 'Invited',
    cursor: 'cu-na'
  },
  [STATUS_DISABLED]: {
    fontColor: 'co-primary-3',
    iconName: 'check',
    label: 'Enable',
    cursor: 'cu-p'
  },
  [STATUS_NOT_INVITED]: {
    fontColor: 'co-primary-3',
    iconName: 'plus',
    label: 'Invite',
    cursor: 'cu-p'
  },
  default: {
    fontColor: 'co-gray-2',
    iconName: null,
    label: 'N/A',
    cursor: 'cu-na'
  }
}

// Renders (ACTIVE / INVITED / DISABLED / NOT INVITED) action
const UserStatusSwitch = ({ statusId, disabled: initialDisabled, onClick }) => {
  let attributes = actions[statusId]
  let disabled = initialDisabled
  if (!attributes && !disabled) {
    disabled = true
    attributes = actions.default
  }
  return (
    <Action
      onClick={event => !disabled && onClick(event)}
      disabled={disabled}
      {...attributes}
    />
  )
}
UserStatusSwitch.propTypes = {
  statusId: number,
  disabled: bool,
  onClick: func
}
UserStatusSwitch.defaultProps = {
  statusId: -1,
  disabled: false,
  onClick: () => null
}

const TableTr = memo(props => {
  const {
    id,
    firstName,
    lastName,
    email,
    status,
    lastLogin,
    userRoles,
    isBlocked,
    bulkUpdating,
    checked,
    currentUserId,
    onRolesChange,
    onInvite,
    onStatusChange,
    onCheckboxClick
  } = props
  const { id: statusId, label: statusLabel } = status || {}

  const canEditUser = useMemo(() => {
    return (
      statusId === STATUS_ACTIVE ||
      statusId === STATUS_DISABLED ||
      statusId === STATUS_INVITED
    )
  }, [statusId])

  const memoUserRoles = useMemo(
    () =>
      canEditUser
        ? userRoles.filter(
            role => role && [USER_ROLE, UTILITIES_ROLE].indexOf(role.id) === -1
          )
        : [],
    [canEditUser, userRoles]
  )

  return (
    <div
      className={cx('di-f', 'fldi-r', 'flwr-nw', 'fosi-2', classes.tableRow, {
        [styles.clickable]: bulkUpdating && id !== currentUserId,
        [styles.checked]: bulkUpdating && checked,
        'cu-d': id === currentUserId
      })}
      role="row"
      onClick={() => {
        if (bulkUpdating) {
          onCheckboxClick()
        }
      }}
    >
      <div className={cx(classes.wrapper, classes.attributes)}>
        <div
          className={cx(
            bulkUpdating ? classes.wrapper : 'di-n',
            classes.idCheckbox
          )}
        >
          <div className={cx(classes.column, classes.id)} role="cell">
            {id !== currentUserId && (
              <Checkbox.Controlled
                id={id}
                name={`id[${id}]`}
                checked={checked}
                onClick={onCheckboxClick}
              />
            )}
          </div>
        </div>
        <div className={cx(classes.wrapper, classes.nameEmail)}>
          <div
            className={cx(classes.column, classes.name, 'fowe-b')}
            role="cell"
          >
            {`${firstName || ''} ${lastName || ''}`}
          </div>

          <div className={cx(classes.column, classes.email)} role="cell">
            <span>{email || ''}</span>
          </div>
        </div>
        <div className={cx(classes.wrapper, classes.statusLastLogin)}>
          <div className={cx(classes.column, classes.status)} role="cell">
            {statusLabel || ''}
          </div>

          <div className={cx(classes.column, classes.lastLogin)} role="cell">
            {lastLogin ? Formatter.date(lastLogin, 'MM/DD/YYYY') : ''}
          </div>
        </div>
      </div>
      <div className={cx(classes.wrapper, classes.attributes, classes.actions)}>
        <div className={cx(classes.wrapper, classes.accessLevelActionReset)}>
          <div
            className={cx('co-primary-3', classes.column, classes.accessLevel)}
            role="cell"
          >
            <FlatButton
              className={styles.buttonChangeRoles}
              onClick={() => canEditUser && onRolesChange()}
              disabled={!canEditUser || bulkUpdating}
            >
              <PreviewRoles canEdit={canEditUser} roles={memoUserRoles} />
            </FlatButton>
          </div>
          <div className={cx(classes.column, classes.action)} role="cell">
            <UserStatusSwitch
              statusId={statusId}
              onClick={() => {
                if (id !== currentUserId) {
                  if (statusId === STATUS_NOT_INVITED) {
                    onInvite({ id })
                  } else if (
                    [STATUS_ACTIVE, STATUS_DISABLED].indexOf(statusId) !== -1
                  ) {
                    onStatusChange({ isBlocked, id })
                  }
                }
              }}
              disabled={
                !(id !== currentUserId && typeof statusId !== 'undefined') ||
                bulkUpdating
              }
            />
          </div>
          <div
            className={cx(
              'co-primary-3',
              classes.column,
              classes.resetPassword
            )}
            role="cell"
          >
            <PasswordReset
              idAuth={id}
              disabled={
                statusId === STATUS_DISABLED || !canEditUser || bulkUpdating
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
})

TableTr.propTypes = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  status: object,
  lastLogin: string,
  userRoles: array,
  isBlocked: bool,
  bulkUpdating: bool,
  checked: bool,
  currentUserId: string,
  onRolesChange: func,
  onInvite: func,
  onStatusChange: func,
  onCheckboxClick: func
}

TableTr.defaultProps = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  status: null,
  lastLogin: null,
  userRoles: [],
  isBlocked: false,
  bulkUpdating: false,
  checked: false,
  currentUserId: null,
  onRolesChange: () => null,
  onInvite: () => null,
  onStatusChange: () => null,
  onCheckboxClick: () => null
}

export default TableTr

import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from 'react'
import cx from 'classnames'
import { string, object } from 'prop-types'

// hooks
import useUserProfile from '@hooks/useUserProfile'

// modules
import Cookie from '@modules/cookie'
import {
  handleCreate,
  handleEditFormErrors
} from '@components/EditEmployeeForm/modules/helpers'
import Url, { ARRAY_INDICES } from '@modules/url'
import { Collection } from '@modules/rest-api'
import {
  REST_API_ENDPOINTS,
  API_VERSION,
  ADMIN_ROLE,
  USER_ROLES,
  IMPERSONATED_USER_ROLES,
  IMPERSONATION_COOKIE_NAME,
  USER_ROLE,
  UTILITIES_ROLE,
  MONTHS
} from '@modules/constants'
import Auth from '@modules/auth'
import { SORT_ASC, SORTBY_NAME } from './modules/constants'

// Components
import { ToastNotify } from '@compositions/Toast'
import Layout from '@compositions/Layout'
import PrivateRoute from '@components/PrivateRoute'
import Button from '@components/Button'
import Loader from '@components/Loader'
import Icon from '@components/Icon'
import Paginator from '@components/Paginator'
import { THEMES } from '@components/constants'
import FeatureToggle from '@components/FeatureToggle'
import EditEmployeeForm from '@components/EditEmployeeForm'
import Switch from '@components/Switch'
import Table from './components/Table'
import ChangeUserRoles from './components/ChangeUserRoles'
import ConfirmationModal from './components/ConfirmationModal'
import FilterBar from '@components/FilterBar'

// Context
import ContextWrapper from './context'
import { SettingsContext } from './context'

// hooks
import useIsImpersonator from '@hooks/useIsImpersonator'

// modules
import { BulkUsersUpdate, handleFileDownload } from './modules/helpers'

const searchAsObject = search => Url.parseSearch(search)

const updateUrl = (path, search) => {
  return params => {
    Url.updateSearch(
      path,
      {
        ...search,
        ...params
      },
      null,
      { arrayFormat: ARRAY_INDICES }
    )
  }
}

const Settings = props => {
  const { path, location } = props
  const memoSearch = useMemo(() => searchAsObject(location.search), [
    location.search
  ])
  const impersonator = Cookie.getAsJson(IMPERSONATION_COOKIE_NAME, null)
  const { sub: loggedInUser } = useUserProfile()
  const [enableBulkActions, setEnableBulkActions] = useState(false)
  const [bulkToggleStatus, setBulkToggleStatus] = useState(false)
  const [bulkEditRoles, setBulkEditRoles] = useState(false)
  const [selected, setSelected] = useState(new Map())
  const [users, setUsers] = useState({ isLoading: true, data: [], error: null })
  const [, setMeta] = useState({ isLoading: true, data: {}, error: null })
  const [updatedUser, setUpdatedUser] = useState(null)

  const {
    addEmployee,
    setAddEmployee,
    errors,
    setErrors,
    communities,
    availableRoles,
    errorAvailableRoles,
    employeeRoles
  } = useContext(SettingsContext)

  const currentUserId = useMemo(() => {
    let userId = loggedInUser
    if (typeof impersonator === 'object' && impersonator.hasOwnProperty('id')) {
      userId = impersonator.id
    }
    return userId
  }, [impersonator, loggedInUser])

  // Load roles available
  const memoRolesList = useMemo(() => {
    const list = availableRoles.data || []
    return list
      .filter(
        item => item && [USER_ROLE, UTILITIES_ROLE].indexOf(item.id) === -1
      )
      .map(item => ({ ...item, value: item.label }))
  }, [availableRoles])

  if (errorAvailableRoles) {
    ToastNotify.info('There was an error loading the roles')
  }

  // Bulk update user's status
  const callbackHandleBulkStatusUpdate = useCallback(() => {
    if (selected.size === 0) {
      return setBulkToggleStatus(false)
    }
    const markBulkAsBlocked = bulkToggleStatus === 'disable'
    BulkUsersUpdate(
      Array.from(selected.values()),
      { updateIsBlockedStatus: markBulkAsBlocked },
      usersPayload => {
        if (usersPayload && usersPayload.length > 0) {
          setUpdatedUser(new Map(usersPayload.map(item => [item.email, item])))
        }
        setSelected(new Map())
        setBulkToggleStatus(false)
      }
    )
  }, [bulkToggleStatus, selected, setBulkToggleStatus, setUpdatedUser])

  // Load Users Data
  useEffect(() => {
    const usersCollection = new Collection(
      REST_API_ENDPOINTS.users,
      API_VERSION
    )

    setUsers({
      isLoading: true,
      data: [],
      error: null
    })

    usersCollection.readSigned(
      {
        params: { itemsPerPage: 3000, ...memoSearch },
        stringifyOpts: { arrayFormat: ARRAY_INDICES }
      },
      (err, res) => {
        if (err) {
          console.warn(err)
          setUsers({
            isLoading: false,
            error: err
          })
          return
        }

        const { data: response } = res || {}
        const data = (response && response.results) || []
        const meta = (response && response.meta) || {}

        setUsers({
          isLoading: false,
          data,
          error: null
        })

        setMeta({
          isLoading: false,
          data: {
            page: meta.page || 1,
            itemsPerPage: meta.itemsPerPage || 10,
            totalItems: meta.totalItems || 0
          },
          errors: null
        })
      }
    )
  }, [memoSearch])

  useEffect(() => {
    if (updatedUser && users.data) {
      let newUsersList = users.data
      if (updatedUser instanceof Map) {
        newUsersList = users.data.map(user => {
          let newUser = Object.assign({}, user)
          if (updatedUser.has(user.email)) {
            newUser = updatedUser.get(user.email)
          }
          return newUser
        })
      } else {
        newUsersList = users.data.map(user => {
          return user.email === updatedUser.email
            ? { ...user, ...updatedUser }
            : user
        })
      }

      setUsers(prevState => ({ ...prevState, data: newUsersList }))
      setUpdatedUser(null)
    }
  }, [updatedUser, users.data])

  function handleClickCheckbox(user, checked, toggleAll = false) {
    if (toggleAll) {
      if (users.data.length - 1 === selected.size) {
        setSelected(new Map())
      } else {
        const usersCopy = users.data
          .slice()
          .filter(user => user.id !== currentUserId)
        setSelected(new Map(usersCopy.map(item => [item.id, item])))
      }
    } else {
      if (user.id === currentUserId) return false
      setSelected(prevState => {
        const selectedCopy = new Map(prevState)
        if (checked) selectedCopy.delete(user.id)
        else selectedCopy.set(user.id, user)
        return selectedCopy
      })
    }
  }

  function handleToggleBulkActions(_, checked) {
    setEnableBulkActions(!checked)
    if (checked) {
      setSelected(new Map())
    }
  }
  function handleDownload() {
    const loadingId = ToastNotify.info('Downloading file, please wait...')
    const date = new Date()
    const monthAsNumber = date.getMonth()
    const fullYear = date.getFullYear()
    const filename = `Users ${MONTHS[monthAsNumber]} ${fullYear}`
    handleFileDownload(memoSearch, filename, (err, res) => {
      ToastNotify.remove(loadingId)
      if (err) return ToastNotify.error('Unable to download users file.')
      ToastNotify.success(`File '${filename}.xlsx' downloaded successfully!`)
    })
  }

  if (errorAvailableRoles) {
    ToastNotify.info('There was an error loading the roles')
  }

  const prefix = 'users'

  return (
    <>
      {users.isLoading ? (
        <Loader.Grid className="pat-4" template="Leads" />
      ) : (
        <>
          <p className="fosi-3 pab-2">
            Use the table below to manage your users. Invited users will receive
            an email link to activate their account.
          </p>
          <p className="fosi-3 pab-4">
            Disabled accounts cannot log in to this site but will not be deleted
            and may be invited to this site at any time.
          </p>
          <div className="di-f juco-sb pab-2">
            <div className="di-f">
              <Button
                className="fosi-2 pay-2 mar-1"
                palette={THEMES.primary}
                onClick={() => setAddEmployee(true)}
              >
                <Icon className="mar-2" name="plus" size={12} />
                Request A New User
              </Button>
              <Button
                className="fosi-2 pay-2 mar-1"
                palette={THEMES.primary}
                onClick={handleDownload}
              >
                Export
              </Button>
              <FeatureToggle name="admin-settings">
                <Button className="fosi-2 pay-2 mar-1" palette={THEMES.white}>
                  Hide Disabled Accounts
                </Button>
              </FeatureToggle>
            </div>
            <Switch
              className="pal-2"
              label={`${
                enableBulkActions ? 'Disable' : 'Enable'
              } Bulk Updating`}
              checked={enableBulkActions}
              onChange={handleToggleBulkActions}
            />
          </div>
          <div
            className={cx(
              enableBulkActions ? 'di-f' : 'di-n',
              'juco-s',
              'pat-1',
              'pab-3'
            )}
          >
            <Button
              className="fosi-2 pay-2 mar-1"
              onClick={() => setBulkEditRoles(true)}
              disabled={selected.size === 0}
            >
              Bulk edit roles
            </Button>
            <Button
              className="fosi-2 pay-2 mar-1"
              disabled={selected.size === 0}
              onClick={() => setBulkToggleStatus('enable')}
            >
              Bulk enable
            </Button>
            <Button
              className="fosi-2 pay-2 mar-1"
              disabled={selected.size === 0}
              onClick={() => setBulkToggleStatus('disable')}
            >
              Bulk disable
            </Button>
          </div>
          <div className="di-f pab-2">
            <FilterBar
              onFilter={updateUrl(path, memoSearch)}
              sortOrder={
                typeof memoSearch.sortOrder === 'undefined'
                  ? SORT_ASC
                  : +memoSearch.sortOrder
              }
              sortColumn={+memoSearch.sortColumn || SORTBY_NAME}
              memoSearch={memoSearch}
              availableRoles={memoRolesList}
            />
          </div>
          {addEmployee && (
            <EditEmployeeForm
              isNew={true}
              withCommunityField={true}
              rolesDataSource={employeeRoles}
              communitiesDataSource={communities}
              closeModal={() => setAddEmployee(false)}
              errors={errors}
              onFormSubmit={({ model, callback }) => {
                const selectedCommunity = communities.data.find(
                  community =>
                    parseInt(community.id, 10) === parseInt(model.community, 10)
                )

                delete model.community

                return handleCreate(
                  REST_API_ENDPOINTS.users,
                  {
                    ...model,
                    communityId: selectedCommunity.id,
                    communityName: selectedCommunity.value
                  },
                  employeeRoles,
                  (err, res) => {
                    const networkIssues = handleEditFormErrors(err, setErrors)
                    const status = (res && res.status) || 500
                    callback(!err && status === 200, networkIssues)
                  }
                )
              }}
            />
          )}
          <Table
            className="mab-1"
            currentUserId={currentUserId}
            availableRoles={memoRolesList}
            users={users.data}
            onSort={updateUrl(path, memoSearch)}
            onUserUpdated={setUpdatedUser}
            sortOrder={
              typeof memoSearch.sortOrder === 'undefined'
                ? SORT_ASC
                : +memoSearch.sortOrder
            }
            sortColumn={+memoSearch.sortColumn || SORTBY_NAME}
            bulkUpdating={enableBulkActions}
            selected={selected}
            onClickCheckbox={handleClickCheckbox}
            memoSearch={memoSearch}
          />
          <FeatureToggle name="admin-settings">
            <Paginator
              prefix={prefix}
              onClickNext={() => {}}
              onClickPrev={() => {}}
              onClickStep={() => {}}
              currentPage={1}
              totalPages={3}
              className="mat-3"
            />
          </FeatureToggle>
        </>
      )}
      {bulkToggleStatus && (
        <ConfirmationModal
          currentUserId={currentUserId}
          bulkTotal={selected.size}
          isBlocked={bulkToggleStatus === 'enable'}
          closeModal={() => setBulkToggleStatus(false)}
          onConfirm={callbackHandleBulkStatusUpdate}
          bulkEdit
        />
      )}
      <ChangeUserRoles
        show={!!bulkEditRoles}
        currentUserId={currentUserId}
        roles={memoRolesList}
        users={Array.from(selected.values())}
        onClose={usersPayload => {
          if (usersPayload && usersPayload.length > 0) {
            setUpdatedUser(
              new Map(usersPayload.map(item => [item.email, item]))
            )
          }
          setBulkEditRoles(false)
          setSelected(new Map())
        }}
        bulkEdit
      />
    </>
  )
}

Settings.propTypes = {
  path: string,
  location: object
}

Settings.defaultProps = {
  path: '/',
  location: null
}

const SettingsWrapper = props => {
  const [validating, setValidating] = useState(true)
  const [userType, setUserType] = useState(null)
  const isImpersonator = useIsImpersonator()
  const breadcrumbs = [
    { key: 'admin-settings', text: 'Admin Settings', url: props.path }
  ]

  useEffect(() => {
    setValidating(true)
    Auth.isoIsImpersonating((_, isImpersonating) => {
      setImmediate(() => {
        if (isImpersonating) {
          setUserType(IMPERSONATED_USER_ROLES)
        } else {
          setUserType(USER_ROLES)
        }
        setValidating(false)
      })
    })
  }, [isImpersonator])

  return (
    <Layout
      className="baco-white"
      breadcrumbs={breadcrumbs}
      flagName="admin-settings"
    >
      {validating ? (
        <p>Validating user...</p>
      ) : (
        <PrivateRoute
          userType={userType}
          roles={[ADMIN_ROLE]}
          component={() => (
            <ContextWrapper>
              <Settings {...props} />
            </ContextWrapper>
          )}
          {...props}
        />
      )}
    </Layout>
  )
}

SettingsWrapper.propTypes = {
  path: string
}

SettingsWrapper.defaultProps = {
  path: '/'
}

export default SettingsWrapper

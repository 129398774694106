import React, { memo, useMemo } from 'react'
import cx from 'classnames'
import { bool, oneOfType, func, node } from 'prop-types'

// components
import Button from '@components/Button'
import Icon from '@components/Icon'
import Message from '../Message'

// styles
import styles from './_.module.scss'

const DropdownActions = memo(props => {
  const {
    loading,
    readOnly,
    isEditMode,
    isDropdownOpen,
    actionsBuilder: ActionsBuilder
  } = props
  if (readOnly) return null
  const config = useMemo(() => {
    if (!isEditMode) return { title: 'Edit', icon: <Icon name="pencil" /> }
    if (isDropdownOpen)
      return { title: 'Collapse options', icon: <Icon name="chevron-up" /> }
    return { title: 'Expand options', icon: <Icon name="chevron-down" /> }
  }, [isEditMode, isDropdownOpen])

  const className = cx('po-a', styles.actions)
  if (ActionsBuilder) {
    return typeof ActionsBuilder === 'function' ? (
      ActionsBuilder({ ...props, className })
    ) : (
      React.cloneElement(ActionsBuilder, { className })
    )
  }

  return (
    <div className={className}>
      {loading ? (
        <Message
          className={styles.message}
          type={Message.SAVING}
          message="Saving..."
        />
      ) : (
        <Button
          palette="flat"
          className={cx('juco-c', styles.buttonIcon)}
          title={config.title}
        >
          {config.icon}
        </Button>
      )}
    </div>
  )
})

DropdownActions.propTypes = {
  loading: bool,
  readOnly: bool,
  isEditMode: bool,
  isDropdownOpen: bool,
  actionsBuilder: oneOfType([func, node])
}

DropdownActions.defaultProps = {
  loading: false,
  isDropdownOpen: false,
  readOnly: false,
  isEditMode: false,
  actionsBuilder: null
}

export default DropdownActions

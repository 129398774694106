import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'

// components
import Button from '@components/Button'

import styles from './_.module.scss'

const FlatButton = props => (
  <Button
    {...props}
    palette="flat"
    className={cx(styles.flatButton, props.className, 'pa-2')}
    type="button"
  />
)

FlatButton.propTypes = {
  className: string
}
FlatButton.defaultProps = {
  className: ''
}

export default FlatButton
import React, { memo } from 'react'
import cx from 'classnames'
import { string, bool, func } from 'prop-types'

// components
import Button from '@components/Button'

// styles
import styles from './_.module.scss'

const Actions = memo(
  ({
    editMode,
    dropdownClosed,
    className,
    disabled,
    showCancelOnly,
    saveText,
    cancelText,
    onSave,
    onCancel
  }) => {
    return (
      <div className={cx('di-f', 'alit-c', 'po-r', styles.buttons, className)}>
        {(editMode && dropdownClosed) || showCancelOnly ? (
          <>
            {!showCancelOnly && (
              <Button
                palette="flat"
                className={cx(styles.button, styles.buttonPrimary)}
                onClick={onSave}
                disabled={disabled}
              >
                {saveText}
              </Button>
            )}
            <Button
              palette="flat"
              className={cx(styles.button, styles.buttonCancel)}
              onClick={onCancel}
              disabled={disabled}
            >
              {cancelText}
            </Button>
          </>
        ) : (
          <span className="pay-2 may-1"> </span>
        )}
      </div>
    )
  }
)

Actions.propTypes = {
  editMode: bool,
  dropdownClosed: bool,
  disabled: bool,
  showCancelOnly: bool,
  saveText: string,
  cancelText: string,
  className: string,
  onSave: func,
  onCancel: func
}

Actions.defaultProps = {
  editMode: true,
  dropdownClosed: true,
  disabled: false,
  showCancelOnly: false,
  saveText: 'Save',
  cancelText: 'Cancel',
  className: null,
  onSave: () => null,
  onCancel: () => null
}

export default Actions

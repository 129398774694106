import { useState, useEffect } from 'react'

//modules
import Auth from '@modules/auth'

const useUserProfile = () => {
    const [userProfile, setUserProfile] = useState(false)

    useEffect(() => {
        Auth.clientFetchProfile((_, profile) => {
            if(profile){
                setUserProfile(profile)
            }
        })
    }, [])

    return userProfile
}

export default useUserProfile
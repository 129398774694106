import React from 'react'
import { any, shape, func, number, string, object } from 'prop-types'
import cx from 'classnames'

// components
import SortingArrow from '../SortingArrow'

// modules
import { SORT_ASC, SORT_DESC, SORTBY_NAME } from '../../modules/constants'

// styles
import styles from '../Table/_.module.scss'

const classes = {
  ...styles,
  columnHeader: cx('fosi-1', 'di-f'),
  columnHeaderActive: cx('co-black', 'fowe-b')
}

const ColumnShape = shape({
  sortColumn: number,
  text: string,
  classNamePointer: string
})

const SyntheticColumnHeader = ({
  children,
  onClick,
  sortColumn,
  sortOrder,
  className,
  column,
  memoSearch
}) => {
  let sortOrderInverse = sortOrder
  let isActive = false
  if (column.sortColumn === sortColumn) {
    isActive = true
    sortOrderInverse = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC
  }

  return (
    <div
      onClick={() =>
        column.isFilter
          ? onClick({
              ...memoSearch,
              sortColumn: column.sortColumn,
              sortOrder: sortOrderInverse
            })
          : null
      }
      className={cx(
        classes.column,
        classes.columnHeader,
        classes[column.classNamePointer] || '',
        className,
        `${column.isFilter ? 'cu-p' : 'cu-na'}`,
        { [classes.columnHeaderActive]: isActive },
        `qa-table-header__action--sort-${column.classNamePointer}`
      )}
      role="columnheader"
    >
      {column.text} {children}
      {sortColumn === column.sortColumn && column.isFilter && (
        <SortingArrow sortOrder={sortOrder} />
      )}
    </div>
  )
}

SyntheticColumnHeader.propTypes = {
  column: ColumnShape.isRequired,
  children: any,
  className: string,
  sortColumn: number,
  sortOrder: number,
  memoSearch: object,
  onClick: func
}

SyntheticColumnHeader.defaultProps = {
  onClick: () => null,
  className: '',
  children: null,
  sortColumn: SORTBY_NAME,
  sortOrder: SORT_ASC,
  memoSearch: {},
  column: {}
}

export default SyntheticColumnHeader

import React, { useState, memo } from 'react'
import { string, bool } from 'prop-types'
import cx from 'classnames'

// components
import FlatButton from '@components/FlatButton'
import Icon from '@components/Icon'

// Compositions
import { ToastNotify } from '@compositions/Toast'

// Modules
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'

// styles
import styles from './_.module.scss'

const handlePasswordReset = (idAuth, setStatus) => {
  const loadingMsgId = ToastNotify.info(
    "Resetting the selected user's password."
  )
  setStatus({ isLoading: true })
  const resource = new Collection(
    REST_API_ENDPOINTS.userPasswordReset,
    API_VERSION
  )
  resource.createSigned({ idAuth }, {}, error => {
    ToastNotify.remove(loadingMsgId)
    setStatus({ isLoading: false })
    if (error) {
      const { response } = error || {}
      const { status } = response || {}
      if (status !== 403) {
        ToastNotify.error(
          'There was an error resetting this password, please try again later.'
        )
      }
      return console.error(error)
    }

    ToastNotify.success(
      'An email has been sent with instructions for resetting a password to the chosen user.'
    )
  })
}

const PasswordReset = memo(({ idAuth, disabled }) => {
  const [passwordResetStatus, setPasswordResetStatus] = useState({
    isLoading: false
  })

  return (
    <FlatButton
      className={cx('whsp-nw', 'fosi-2', styles.buttonReset, {
        [styles.disabled]: passwordResetStatus.isLoading
      })}
      onClick={() => !disabled && handlePasswordReset(idAuth, setPasswordResetStatus)}
      disabled={disabled}
    >
      <Icon className="mar-2" name="lock" size={12} /> <span>Reset Password</span>
    </FlatButton>
  )
})

PasswordReset.propTypes = {
  idAuth: string.isRequired,
  disabled: bool
}

PasswordReset.defaultProps = {
  disabled: false
}

export default PasswordReset

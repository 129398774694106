import {
  // State constants
  STATE_EDIT_MODE,
  STATE_EDIT_CANCEL,
  STATE_EDIT_SAVING,
  STATE_ADD_SELECTED,
  STATE_REMOVE_SELECTED,
  STATE_INPUT_FOCUS,
  STATE_VALUE,
  STATE_EDIT_COMPLETED,
  // Autocomplete modes
  MODE_EDIT,
  MODE_READONLY,
  // Dropdown state
  DROPDOWN_OPEN,
  DROPDOWN_CLOSED,
  // Dropdown Offset
  STATE_OFFSET_HEIGHT,
  STATE_MODAL_BLOCKER
} from '../constants'

export function normalizeData(arrayData) {
  const initializeSaved = arrayData.map(item => [item.id, item])
  return new Map(initializeSaved)
}
export function init(validationError = null, preselectedOptions = []) {
  const saved = normalizeData(preselectedOptions)
  return function(state) {
    return {
      ...state,
      exitting: false,
      validationError,
      saved,
      selected: saved,
      blockCloseModal: false,
      unSavedChanges: false
    }
  }
}
export function reducer(state, action) {
  if (state.exitting) return state
  const { type, payload } = action
  switch (type) {
    case STATE_EDIT_MODE: {
      const { offsetHeight } = payload
      return {
        ...state,
        mode: MODE_EDIT,
        dropdownStatus: DROPDOWN_OPEN,
        validationError: null,
        inputFocus: true,
        offsetHeight
      }
    }
    case STATE_EDIT_CANCEL: {
      return {
        ...state,
        mode: MODE_READONLY,
        dropdownStatus: DROPDOWN_CLOSED,
        selected: state.saved,
        inputFocus: false,
        blockCloseModal: false,
        unSavedChanges: false,
        value: state.value
      }
    }
    case STATE_EDIT_SAVING: {
      return {
        ...state,
        dropdownStatus: DROPDOWN_CLOSED,
        loading: true
      }
    }
    case STATE_ADD_SELECTED: {
      const { id, value } = payload
      const selectedAdd = new Map(state.selected.entries())
      selectedAdd.set(id, value)
      return {
        ...state,
        selected: selectedAdd,
        dropdownStatus: DROPDOWN_CLOSED,
        value: '',
        inputFocus: true,
        unSavedChanges: true
      }
    }
    case STATE_REMOVE_SELECTED: {
      const { id } = payload
      const selectedRemove = new Map(state.selected.entries())
      selectedRemove.delete(id)
      return {
        ...state,
        selected: selectedRemove,
        dropdownStatus: DROPDOWN_CLOSED,
        value: '',
        inputFocus: true,
        unSavedChanges: true
      }
    }
    case STATE_INPUT_FOCUS: {
      const { focus: isFocused, offsetHeight } = payload
      return {
        ...state,
        mode: isFocused ? MODE_EDIT : state.mode,
        dropdownStatus: isFocused ? DROPDOWN_OPEN : DROPDOWN_CLOSED,
        inputFocus: isFocused,
        validationError: null,
        value: '',
        offsetHeight
      }
    }
    case STATE_VALUE: {
      const notEmpty = `${payload || ''}`.length > 0
      return {
        ...state,
        value: payload,
        validationError: notEmpty ? null : state.notEmpty,
        dropdownStatus: notEmpty ? DROPDOWN_OPEN : state.dropdownStatus
      }
    }
    case STATE_EDIT_COMPLETED: {
      const { saved, validationError } = payload
      return {
        ...state,
        mode: MODE_READONLY,
        loading: false,
        dropdownStatus: DROPDOWN_CLOSED,
        inputFocus: false,
        selected: !validationError ? saved : state.saved,
        saved: !validationError ? saved : state.saved,
        validationError,
        blockCloseModal: false,
        unSavedChanges: false
      }
    }
    case STATE_OFFSET_HEIGHT: {
      return {
        ...state,
        offsetHeight: payload
      }
    }
    case STATE_MODAL_BLOCKER: {
      return {
        ...state,
        blockCloseModal: payload
      }
    }
    default:
      break
  }
  return state
}

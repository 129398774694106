import React from 'react'
import { any, func, object } from 'prop-types'

import Select from '@components/Select'

const SelectFieldGroup = ({ defaultValue, onChange, dataSource, ...props }) => {
  const { data, isLoading, error } = dataSource || {}
  return (
    <Select
      {...props}
      value={defaultValue}
      onChange={event => onChange(event.currentTarget.value)}
    >
      {isLoading ? (
        <div className="fosi-1 co-gray-3">Loading...</div>
      ) : error ? (
        <div className="fosi-1 co-gray-3">
          There was an error loading the options
        </div>
      ) : (
        <>
          <option value="">Select option</option>
          {data.length > 0 &&
            data.map((item, key) => (
              <option key={key} value={item.id}>
                {item.value}
              </option>
            ))}
        </>
      )}
    </Select>
  )
}
SelectFieldGroup.propTypes = {
  defaultValue: any.isRequired,
  dataSource: object.isRequired,
  onChange: func.isRequired
}

export default SelectFieldGroup

import {
  STATUS_ACTIVE,
  STATUS_DISABLED,
  STATUS_INVITED,
  STATUS_NOT_INVITED
} from '@compositions/Settings/modules/constants'

export const statuses = [
  {
    id: STATUS_ACTIVE,
    value: 'Active'
  },
  {
    id: STATUS_DISABLED,
    value: 'Disable'
  },
  { id: STATUS_INVITED, value: 'Invited' },
  { id: STATUS_NOT_INVITED, value: 'Not Invited' }
]

import React from 'react'
import { func, bool } from 'prop-types'
import EditControls from '@components/EditControls'
import { THEMES } from '@components/constants'

const Footer = ({ updating, exit, setUpdating, onConfirm }) => {
  return (
    !updating && (
      <EditControls
        disabled={updating}
        onCancel={exit}
        okText="Yes"
        okPalette={THEMES.orange}
        cancelText="No"
        onSave={() => {
          setUpdating(true)
          onConfirm()
        }}
      />
    )
  )
}

Footer.propTypes = {
  updating: bool,
  exit: func,
  setUpdating: func,
  onConfirm: func
}
Footer.defaultProps = {
  updating: false,
  exit: () => null,
  setUpdating: () => null,
  onConfirm: () => null
}

export default Footer

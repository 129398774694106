import React from 'react'
import { number } from 'prop-types'

// components
import Icon from '@components/Icon'

// modules
import { SORT_ASC } from '../../modules/constants'

const SortingArrow = ({ sortOrder }) => (
    <Icon
      style={sortOrder === SORT_ASC ? { transform: 'rotate(180deg)' } : {}}
      name="arrow-up"
      size={8}
    />
  )
  
  SortingArrow.propTypes = {
    sortOrder: number
  }
  
  SortingArrow.defaultProps = {
    sortOrder: SORT_ASC
  }

  export default SortingArrow
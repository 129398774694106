import React from 'react'
import { any, func } from 'prop-types'

import TextField from '@components/TextField'

const TextFieldGroup = ({ defaultValue, onChange, ...props }) => (
  <TextField
    palette="white"
    value={(defaultValue && defaultValue.value) || ''}
    {...props}
    type="email"
    onChange={event =>
      onChange({ value: event.currentTarget.value, id: defaultValue.id })
    }
  />
)

TextFieldGroup.propTypes = {
  defaultValue: any.isRequired,
  onChange: func.isRequired
}

export default TextFieldGroup

import React, { memo } from 'react'
import { bool, node, func, string } from 'prop-types'

// components
import EventOutsideDetector from '@components/EventOutsideDetector'

const AutocompleteWrapper = memo(
  ({ listeningClickOutside, className, children, onEventOutside }) => {
    return listeningClickOutside ? (
      <EventOutsideDetector className={className} eventName="mousedown" onEvent={onEventOutside}>
        {children}
      </EventOutsideDetector>
    ) : (
      children
    )
  }
)

AutocompleteWrapper.propTypes = {
  listeningClickOutside: bool,
  className: string,
  children: node,
  onEventOutside: func
}

AutocompleteWrapper.defaultProps = {
  listeningClickOutside: false,
  className: '',
  children: null,
  onEventOutside: () => null
}

export default AutocompleteWrapper

import React, { memo } from 'react'
import cx from 'classnames'
import { string, oneOf, bool } from 'prop-types'

// styles
import styles from './_.module.scss'

export const SAVING = 'saving'
export const ERROR = 'error'

const Message = memo(({ show, message, type, className }) => {
  if (!show) return <span />
  const messgeTypeClass = styles[type] || ''
  return (
    <div className={cx(styles.message, messgeTypeClass, className)}>
      <span>{message}</span>
    </div>
  )
})

Message.propTypes = {
  message: string,
  className: string,
  type: oneOf([SAVING, ERROR]),
  show: bool
}

Message.defaultProps = {
  type: ERROR,
  className: null,
  message: null,
  show: true
}

Message.ERROR = ERROR
Message.SAVING = SAVING

export default Message

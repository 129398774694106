import React, { memo, useMemo, useContext } from 'react'
import cx from 'classnames'
import { func, object, string, array, bool } from 'prop-types'

// components
import Autocomplete from '@components/Autocomplete'
import FeedbackMessage from '@components/FeedbackMessage'
import TransparentModal from '@components/TransparentModal'

// modules
import { USER_ROLE } from '@modules/constants'
import { SingleUserUpdate, BulkUsersUpdate } from '../../modules/helpers'

// Context
import { SettingsContext } from '../../context'

// styles
import styles from './_.module.scss'

const ChangeUserRoles = memo(
  ({
    show,
    currentUserId,
    users,
    user,
    roles,
    bulkEdit: isBulkEdit,
    onClose
  }) => {
    const { id, roles: selectedRoles, firstName, lastName } = user || {
      id: null,
      firstName: '',
      lastName: '',
      roles: [{ id: USER_ROLE, value: 'User' }]
    }
    const { availableRoles } = useContext(SettingsContext)

    const { isLoading: rolesLoading } = availableRoles || { loading: true }

    const memoSavedRolesMap = useMemo(
      () => new Map(selectedRoles.map(item => [item.id, item])),
      [selectedRoles]
    )
    if (!show) return null

    const handleSaveRolesChange = selectedArray => {
      if (isBulkEdit) {
        BulkUsersUpdate(users, { updateRolesArray: selectedArray }, onClose)
      } else {
        let hasChanged = selectedRoles.length !== selectedArray.length
        if (!hasChanged) {
          if (memoSavedRolesMap.size > 0) {
            const diff = selectedArray.filter(
              item => !memoSavedRolesMap.has(item.id)
            )
            if (diff.length !== 0) hasChanged = true
          } else if (selectedArray.length > 0) {
            hasChanged = true
          }
        }
        if (hasChanged) {
          SingleUserUpdate(id, selectedArray, onClose)
        }
      }
    }

    const handleBackdropClick = () => {
      if (!rolesLoading) return false
      return onClose()
    }

    const isMyProfile = id === currentUserId

    return (
      <TransparentModal
        className={cx('po-r', styles.changeRolesModal)}
        onBackdropClick={handleBackdropClick}
      >
        <div className={cx('di-f', 'fldi-c', styles.contentWrapper)}>
          <h2 className="mab-2">
            <span className="baco-white pax-1 co-black">
              {isBulkEdit
                ? 'Bulk editing of user roles'
                : isMyProfile
                ? 'Your roles'
                : 'Editing roles for '}
              {!isMyProfile && (
                <span className="fowe-b">
                  {firstName} {lastName}
                </span>
              )}
            </span>
          </h2>
          <span className="mab-2">
            {isMyProfile && (
              <FeedbackMessage className="fosi-2" palette="orange">
                You can't modify your own roles
              </FeedbackMessage>
            )}
          </span>
          {rolesLoading ? (
            <span className="pa-2 baco-white bora-1">
              Loading roles, please wait...
            </span>
          ) : (
            <Autocomplete
              placeholder="Roles"
              name="roles"
              limitTags={1}
              options={roles}
              open={true}
              border={false}
              selected={selectedRoles}
              readOnly={id === currentUserId}
              onSave={handleSaveRolesChange}
              onCancel={() => onClose()}
            />
          )}
        </div>
      </TransparentModal>
    )
  }
)

ChangeUserRoles.propTypes = {
  currentUserId: string,
  user: object,
  show: bool,
  bulkEdit: bool,
  users: array,
  roles: array,
  onClose: func
}
ChangeUserRoles.defaultProps = {
  currentUserId: null,
  show: true,
  user: null,
  bulkEdit: false,
  users: [],
  roles: [],
  onClose: () => null
}

export default ChangeUserRoles

import { arrayOf, shape, oneOfType, string, number, bool } from 'prop-types'

export const MODE_EDIT = 'modeEdit'
export const MODE_READONLY = 'modeReadonly'

export const DROPDOWN_OPEN = 'open'
export const DROPDOWN_CLOSED = 'closed'

export const STATE_EDIT_MODE = 'editMode'
export const STATE_EDIT_CANCEL = 'cancelMode'
export const STATE_EDIT_SAVING = 'savingMode'
export const STATE_ADD_SELECTED = 'addSelected'
export const STATE_REMOVE_SELECTED = 'removeSelected'
export const STATE_INPUT_FOCUS = 'inputFocus'
export const STATE_VALUE = 'inputValue'
export const STATE_EDIT_COMPLETED = 'editCompleted'
export const STATE_OFFSET_HEIGHT = 'offsetHeight'
export const STATE_MODAL_BLOCKER = 'modalBlocker'

export const DEFAULT_SIZE = 'default'
export const SMALL = 'small'

export const initialState = {
  loading: false,
  mode: MODE_READONLY,
  inputFocus: false,
  blockCloseModal: false,
  unSavedChanges: false,
  value: '',
  validationError: null,
  offsetHeight: 0,
  dropdownStatus: DROPDOWN_CLOSED,
  saved: new Map(),
  selected: new Map()
}

export const OptionsPropType = arrayOf(
  shape({
    id: oneOfType([string, number]).isRequired,
    value: oneOfType([string, number, bool]).isRequired
  })
)

import React, { useState, memo } from 'react'
import { bool, func, number } from 'prop-types'

import Modal, { SMALL_MODAL } from '@components/Modal'
import LoadingMessage from '@components/EditEmployeeForm/components/LoadingMessage'
import Footer from './components/Footer'

const ConfirmationModal = memo(
  ({ isBlocked, bulkEdit, bulkTotal, closeModal, onConfirm }) => {
    const [updatingUser, setUpdatingUser] = useState(false)
    return (
      <Modal
        className="po-r"
        size={SMALL_MODAL}
        footer={exit => {
          return (
            <Footer
              updating={updatingUser}
              exit={exit}
              setUpdating={setUpdatingUser}
              onConfirm={onConfirm}
            />
          )
        }}
        onToggleActive={() => closeModal()}
      >
        {updatingUser ? (
          <LoadingMessage message="Updating the chosen user" />
        ) : (
          <div className="di-f fldi-c max-2">
            <h4 className="fowe-b mab-1">{`Are you sure you want to ${
              isBlocked ? 'enable' : 'disable'
            } ${bulkEdit ? ` ${bulkTotal} selected users` : 'this user'}?`}</h4>
          </div>
        )}
      </Modal>
    )
  }
)

ConfirmationModal.propTypes = {
  isBlocked: bool,
  bulkEdit: bool,
  bulkTotal: number,
  closeModal: func,
  onConfirm: func
}

ConfirmationModal.defaultProps = {
  isBlocked: false,
  users: [],
  bulkEdit: false,
  bulkTotal: 0,
  closeModal: () => null,
  onConfirm: () => null
}

export default ConfirmationModal

import React, { useState, useEffect } from 'react'
import { func, object, number, array } from 'prop-types'
import cx from 'classnames'

// Components
import Card from '@components/Card'
import Button from '@components/Button'
import Autocomplete from '@components/Autocomplete'
import TextField from '@components/TextField'

// modules
import { statuses } from './modules/constants'

// Styles
import styles from './_.module.scss'

function normalizeRoles(roles, availableRoles = []) {
  return (roles || [])
    .map(
      rol => availableRoles.find(filter => filter.id === +rol) || { id: +rol }
    )
    .filter(item => typeof item !== 'undefined')
}
const FilterBar = ({
  onFilter,
  sortOrder,
  sortColumn,
  memoSearch,
  availableRoles
}) => {
  const [name, setName] = useState(memoSearch.name || '')
  const [email, setEmail] = useState(memoSearch.email)
  const [dateRange, setDateRange] = useState({
    lastLoginStart: memoSearch.lastLoginStart,
    lastLoginEnd: memoSearch.lastLoginEnd
  })

  const [selectedRoles, setSelectedRoles] = useState(
    normalizeRoles(memoSearch.roles, availableRoles)
  )
  const preloadStatuses = (memoSearch.statuses || []).map(status =>
    statuses.find(filter => filter.id === +status)
  )
  const [selectedStatus, setSelectedStatus] = useState(preloadStatuses)

  useEffect(() => {
    setSelectedRoles(normalizeRoles(memoSearch.roles, availableRoles))
  }, [availableRoles, memoSearch.roles])
  const { lastLoginStart, lastLoginEnd } = dateRange || {}

  function handleFilter(clearFilters = false) {
    const selectedRolesIds = selectedRoles.map(item => item.id)
    const selectedStatusIds = selectedStatus.map(item => item.id)
    return function(event) {
      let params = {
        name: name || undefined,
        email: email || undefined,
        lastLoginStart: lastLoginStart || undefined,
        lastLoginEnd: lastLoginEnd || undefined,
        roles: selectedRolesIds.length > 0 ? selectedRolesIds : undefined,
        statuses: selectedStatusIds.length > 0 ? selectedStatusIds : undefined
      }
      if (clearFilters) {
        params = {
          name: undefined,
          email: undefined,
          lastLoginStart: undefined,
          lastLoginEnd: undefined,
          roles: undefined,
          statuses: undefined
        }
      }
      onFilter({
        sortOrder,
        sortColumn,
        ...params
      })
    }
  }
  return (
    <Card className="di-f juco-sb pay-3 pax-3">
      <div className="di-f fldi-r di-n-po alit-c">
        <input
          palette="white"
          className="max-1 pa-2 bora bost-s fosi-2 bowi-1"
          type="text"
          name="name"
          defaultValue={name}
          placeholder="Name"
          onChange={e => setName(e.target.value)}
        />

        <input
          palette="white"
          className="max-1 pa-2 bora bost-s fosi-2 bowi-1 "
          type="text"
          name="email"
          defaultValue={email}
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
        />

        <div className="max-1">
          <div className="po-r">
            <TextField.DateRange
              startDateId="admin-settings-dropdown-option-daterange-start"
              endDateId="admin-settings-dropdown-option-daterange-end"
              label="Last Login"
              onChange={(lastLoginStart, lastLoginEnd) => {
                setDateRange({
                  label: 'Date range selected',
                  lastLoginStart,
                  lastLoginEnd
                })
              }}
              startDate={lastLoginStart ? new Date(lastLoginStart) : null}
              endDate={lastLoginEnd ? new Date(lastLoginEnd) : null}
              returnPartials={true}
              reopenPickerOnClearDates={true}
            />
          </div>
        </div>

        <div className="max-1">
          <Autocomplete
            className={styles.multiselect}
            label="Roles"
            placeholder="Roles"
            name="filterRoles"
            options={availableRoles}
            selected={selectedRoles}
            size="small"
            actionsBuilder={false}
            limitTags={availableRoles.length}
            hideChipsOnEdit
            autoSaveOnSelect
            onSave={(selected, callback) => {
              setSelectedRoles(selected)
              callback(null, { data: selected })
            }}
          />
        </div>
        <div className="max-1">
          <Autocomplete
            className={styles.multiselect}
            label="Status"
            placeholder="Status"
            name="filterStatus"
            options={statuses}
            selected={preloadStatuses}
            size="small"
            actionsBuilder={false}
            limitTags={statuses.length}
            hideChipsOnEdit
            autoSaveOnSelect
            onSave={(selected, callback) => {
              setSelectedStatus(selected)
              callback(null, { data: selected })
            }}
          />
        </div>

        <div className={cx('di-f', 'max-1')}>
          <Button
            className="fldi-r pax-2 pay-2 no tetr-c bora teal-c bosh-1 di-f alit-c alco-c baco-primary-2"
            type="button"
            size="s"
            onClick={handleFilter()}
          >
            <span className="co-primary-1">Filter</span>
          </Button>
          <Button
            className="fldi-r pax-2 pay-2 no tetr-c bora teal-c bosh-1 di-f alit-c alco-c baco-primary-2 max-1"
            type="button"
            size="s"
            onClick={handleFilter(true)}
          >
            <span className="co-primary-1">Reset</span>
          </Button>
        </div>
      </div>
    </Card>
  )
}

FilterBar.propTypes = {
  sortOrder: number,
  sortColumn: number,
  memoSearch: object,
  availableRoles: array,
  onFilter: func
}

FilterBar.defaultProps = {
  sortOrder: 0,
  sortColumn: 0,
  memoSearch: {},
  availableRoles: [],
  onFilter: () => null
}

export default FilterBar

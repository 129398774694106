import React from 'react'
import cx from 'classnames'
import { any, string, number } from 'prop-types'

// hoc
import withPalette from '@hoc/withPalette'
import { THEMES } from '../constants'

const FeedbackMessage = ({ className, palette, size, children }) => (
  <div
    className={cx(
      `pax-${size}`,
      `pay-${size}`,
      'bora',
      `baco-${palette}-3`,
      'co-white',
      className
    )}
    children={children}
  />
)

FeedbackMessage.propTypes = {
  palette: string,
  className: string,
  size: number,
  children: any
}

FeedbackMessage.defaultProps = {
  palette: THEMES.primary,
  className: null,
  size: 1,
  children: null
}

export default withPalette(FeedbackMessage)

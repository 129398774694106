import React from 'react'
import { string, func, bool } from 'prop-types'

// components
import Modal from '@components/Modal'
import Button from '@components/Button'

const ConfirmationModal = ({
  headerText,
  onOk,
  onCancel,
  okText,
  cancelText,
  message,
  onClose,
  hideCloseButton,
  ...rest
}) => {
  return (
    <Modal
      hideCloseButton={hideCloseButton}
      header={<div className="fowe-b co-black">{headerText}</div>}
      footer={exit => {
        return (
          <>
            {cancelText && (
              <Button
                palette="white"
                className="pa-2"
                onClick={() => {
                  onCancel()
                  exit()
                }}
              >
                {cancelText}
              </Button>
            )}
            {okText && (
              <Button
                className="pa-2"
                onClick={() => {
                  onOk()
                  exit()
                }}
              >
                {okText}
              </Button>
            )}
          </>
        )
      }}
      onToggleActive={onClose}
      {...rest}
    >
      {message}
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  headerText: string,
  okText: string,
  cancelText: string,
  message: string,
  hideCloseButton: bool,
  onOk: func,
  onCancel: func,
  onClose: func
}

ConfirmationModal.defaultProps = {
  headerText: 'Are you sure?',
  okText: 'Ok',
  cancelText: 'Cancel',
  message: 'Please confirm',
  hideCloseButton: false,
  onOk: () => null,
  onCancel: () => null,
  onClose: () => null
}

export default ConfirmationModal

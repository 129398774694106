import React from 'react'
import { any, func } from 'prop-types'

// components
import { CheckboxSimple as Checkbox } from '@components/Checkbox'

const CheckboxFieldGroup = ({ name, label, onChange, ...props }) => (
  <Checkbox
    id={name}
    name={name}
    title={label}
    {...props}
    onChange={(_, value) => onChange(value)}
  />
)
CheckboxFieldGroup.propTypes = {
  name: any.isRequired,
  label: any.isRequired,
  onChange: func.isRequired
}

export default CheckboxFieldGroup

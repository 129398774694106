import React, { memo, useState, useMemo, useCallback } from 'react'
import cx from 'classnames'
import { string, func, bool, oneOfType, object, oneOf } from 'prop-types'

// hoc
import withPalette from '@hoc/withPalette'

// components
import { THEMES } from '@components/constants'

// modules
import Formatter from '@modules/formatter'

// styles
import styles from './_.module.scss'

const placementMap = {
  top: styles.switchFormControlLabelTop,
  bottom: styles.switchFormControlLabelBottom,
  left: styles.switchFormControlLabelLeft
}

const Uncontrolled = memo(
  ({
    id,
    className,
    name,
    label,
    checked: controlledChecked,
    defaultChecked,
    value,
    disabled,
    inputProps,
    fontSize,
    palette,
    onClick,
    labelPlacement,
    onChange
  }) => {
    const [isChecked, setIsChecked] = useState(defaultChecked)
    const isUncontrolled = useMemo(() => controlledChecked === null, [
      controlledChecked
    ])
    const paletteClassName = useMemo(() => {
      return `switchControl${Formatter.ucfirst(palette)}`
    }, [palette])
    const labelPlacementClassName = useMemo(
      () => placementMap[labelPlacement] || '',
      [labelPlacement]
    )

    const checked = isUncontrolled ? isChecked : controlledChecked

    const labelAttrs = {}
    if (id) {
      labelAttrs.id = id
    }
    const controlAttrs = {}
    if (disabled) {
      controlAttrs.tabIndex = -1
    }

    const handleOnClick = useCallback(
      event => {
        if (isUncontrolled) {
          setIsChecked(prevState => !prevState)
        } else {
          onChange(event, event.target.checked)
          onClick(event)
        }
      },
      [isUncontrolled, onChange, onClick]
    )
    return (
      <label
        className={cx(
          'di-if',
          'alit-c',
          'cu-p',
          styles.switchFormControl,
          labelPlacementClassName,
          className
        )}
        {...labelAttrs}
      >
        <span
          className={cx(
            'di-if',
            'ov-h',
            'po-r',
            '',
            styles.switchControlWrapper
          )}
        >
          <span
            className={cx(
              'po-a',
              'cu-p',
              'alit-c',
              'teal-c',
              'bora-round',
              styles.switchControl,
              styles[paletteClassName],
              { [styles.switchControlChecked]: checked }
            )}
            aria-disabled={Boolean(disabled)}
            {...controlAttrs}
          >
            <span className="wi-100 di-f">
              <input
                className={cx('po-a', styles.switchInput)}
                name={name}
                checked={checked}
                value={value}
                disabled={disabled}
                {...inputProps}
                onClick={handleOnClick}
                onChange={handleOnClick}
              />
              <span className={cx(styles.switchDot)}></span>
            </span>
          </span>
          <span className={cx(styles.switchTrack)}></span>
        </span>
        <span className={`fosi-${fontSize}`}>{label}</span>
      </label>
    )
  }
)

Uncontrolled.propTypes = {
  id: string,
  className: string,
  label: string,
  name: string,
  checked: bool,
  defaultChecked: bool,
  value: oneOfType([bool, string]),
  disabled: bool,
  palette: string,
  inputProps: object,
  fontSize: oneOf([1, 2, 3, 4]),
  labelPlacement: oneOf(['top', 'bottom', 'left', 'right']),
  onClick: func,
  onChange: func
}

Uncontrolled.defaultProps = {
  id: '',
  className: '',
  label: '',
  name: '',
  checked: null,
  defaultChecked: false,
  value: '',
  disabled: false,
  palette: THEMES.primary,
  inputProps: {},
  fontSize: 2,
  labelPlacement: 'right',
  onClick: () => null,
  onChange: () => null
}

export default withPalette(Uncontrolled)

import React, { memo } from 'react'
import { array, bool } from 'prop-types'

const PreviewRoles = memo(({ canEdit, roles }) => {
  return (
    <span className="di-f fldi-c teal-l fosi-2">
      {roles.length > 0 ? (
        roles.map(role => (
          <span key={role.id} className="mar-1">
            {role.label}
          </span>
        ))
      ) : canEdit ? (
        'Assign'
      ) : (
        <span className="co-gray-2">N/A</span>
      )}
    </span>
  )
})

PreviewRoles.propTypes = {
  canEdit: bool,
  roles: array
}
PreviewRoles.defaultProps = {
  canEdit: false,
  roles: []
}

export default PreviewRoles

import React, { memo } from 'react'
import cx from 'classnames'
import { string, number, func, any, bool } from 'prop-types'

// components
import ScrollableContainer from '@components/ScrollableContainer'
import { OptionsPropType } from '../../modules/constants'

// styles
import styles from './_.module.scss'

const DROPDOWN_TOP_SPACING = 5

const DropdownAutocomplete = memo(
  ({ name, options, open, selectedMap, offsetHeight, onItemClick }) => {
    if (!open) return null
    const length = options.length
    return (
      <ScrollableContainer
        style={{
          maxHeight: '310px',
          top: offsetHeight + DROPDOWN_TOP_SPACING
        }}
        className={cx('po-a', styles.scrollableContainer)}
      >
        <ul
          role="listbox"
          aria-labelledby={`${name}-multiple-select`}
          className={cx('baco-white', styles.dropdown)}
        >
          {length > 0 ? (
            options.map((item, index) => {
              const isItemSelected = selectedMap.has(item.id)
              return (
                <li
                  key={`${name}-${item.item}-${index}`}
                  id={`${name}-multiple-select-option-${index}`}
                  tabIndex={-1}
                  role="option"
                  aria-disabled="false"
                  aria-selected={isItemSelected}
                  className={cx(
                    'cu-p',
                    {
                      [styles.dropdownItemSelected]: isItemSelected
                    },
                    styles.dropdownItem
                  )}
                  onClick={() => onItemClick(item, isItemSelected)}
                >
                  {item.value}
                </li>
              )
            })
          ) : (
            <li
              key={`${name}-no-results`}
              id={`${name}-multiple-select-option-no-results`}
              tabIndex={-1}
              role="option"
              aria-disabled="true"
              aria-selected={false}
              className={cx('cu-p', styles.dropdownItem, styles.noOptions)}
            >
              No options
            </li>
          )}
        </ul>
      </ScrollableContainer>
    )
  }
)

DropdownAutocomplete.propTypes = {
  name: string.isRequired,
  offsetHeight: number.isRequired,
  open: bool,
  options: OptionsPropType,
  selectedMap: any,
  onItemClick: func
}
DropdownAutocomplete.defaultProps = {
  open: false,
  options: [],
  selectedMap: new Map(),
  onItemClick: () => null
}

export default DropdownAutocomplete

// components
import { ToastNotify } from '@compositions/Toast'

// modules
import { Collection } from '@modules/rest-api'
import Env from '@modules/env'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'
import { STATUS_ACTIVE, STATUS_DISABLED, STATUS_NOT_INVITED } from './constants'

function SubmitBulkUpdate(endpoint, data, callback) {
  const loadingMsgId = ToastNotify.info('Saving your changes...')
  const config = {
    apiVersion: API_VERSION,
    data
  }
  const apiRequest = new Collection(endpoint)
  apiRequest.putSigned(config, handleCallback)

  function handleCallback(err, response) {
    ToastNotify.remove(loadingMsgId)
    let payload = null
    if (err) {
      const { response: errResponse } = err || {}
      const { status } = errResponse || {}
      if (status !== 403) {
        ToastNotify.error(
          'There was a problem saving your changes. Please try again later.'
        )
      }
    } else {
      const { data } = response || {}
      const { results } = data || {}
      if (results) {
        payload = results
      }
      ToastNotify.success('Your changes have been saved')
    }
    callback(payload)
  }
}
export function SingleUserUpdate(id, selectedArray, callback) {
  const cleanId = id.replace('auth0|', '')
  const endpoint = `${REST_API_ENDPOINTS.users}/${cleanId}`
  const data = {
    roles: selectedArray.map(item => item.id)
  }
  SubmitBulkUpdate(endpoint, data, callback)
}
export function BulkUsersUpdate(
  users,
  options, // { updateRolesArray, updateIsBlockedStatus, isBulkInvitation },
  callback
) {
  let endpoint = REST_API_ENDPOINTS.usersBulkUpdate
  let rolesUpdate
  if (Array.isArray(options.updateRolesArray)) {
    rolesUpdate = options.updateRolesArray.map(item => item.id)
  }
  const data = users
    .filter(user => {
      const { id: statusId } = user.status || {}
      if (typeof statusId === 'undefined') return false
      else if (options.isBulkInvitation && statusId !== STATUS_NOT_INVITED) return false
      else if ([STATUS_ACTIVE, STATUS_DISABLED].indexOf(statusId) === -1) {
        return false
      }
      return true
    })
    .map(user => {
      const currentUserRoles = user.roles ? user.roles.map(item => item.id) : []
      const { id: statusId } = user.status || {}
      const isBlocked = statusId === STATUS_DISABLED
      return {
        IdAuth: user.id,
        isBlocked: typeof options.updateIsBlockedStatus === 'boolean' ? options.updateIsBlockedStatus : isBlocked,
        roles: rolesUpdate || currentUserRoles
      }
    })
  if (data.length === 0) {
    ToastNotify.success('Your changes have been saved')
    return callback()
  }
  SubmitBulkUpdate(endpoint, data, callback)
}

export const handleFileDownload = (params = {}, filename, cb = () => null) => {
  const collection = new Collection(REST_API_ENDPOINTS.users, 1)

  collection.readSignedSpreadsheet({ params }, (err, res) => {
    if (err) return cb(err)

    const url = Env.getWindow().URL.createObjectURL(new Blob([res.data]))
    const link = Env.getDocument().createElement('a')
    link.href = url
    link.setAttribute('download', `${filename || 'users'}.xlsx`)
    Env.getDocument().body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

    cb(null)
  })
}
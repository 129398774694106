import React from 'react'
import { Router } from '@reach/router'

// compositions
import Settings from '@compositions/Settings'

const SettingsPage = () => {
  return (
    <Router>
      <Settings path="/admin-settings" />
    </Router>
  )
}

export default SettingsPage
import React, { memo, useEffect } from 'react'
import { func, node } from 'prop-types'

// styles
import styles from './_.module.scss'

const TransparentModal = memo(({ onBackdropClick, children }) => {
  useEffect(() => {
    document.body.classList.toggle('ov-h', true)
    return () => {
      document.body.classList.toggle('ov-h', false)
    }
  })
  return (
    <div className={styles.transparentModal}>
      <div
        className={styles.transparentModalBackdrop}
        onClick={onBackdropClick}
      ></div>
      <div className={styles.transparentModalContent}>{children}</div>
    </div>
  )
})

TransparentModal.propTypes = {
  children: node,
  onBackdropClick: func
}

TransparentModal.defaultProps = {
  children: null,
  onBackdropClick: () => null
}

export default TransparentModal

import React, { memo } from 'react'
import cx from 'classnames'
import { string, bool, func } from 'prop-types'

// components
import Icon from '@components/Icon'

// modules
import Formatter from '@modules/formatter'

// styles
import styles from './_.module.scss'

const ChipCustom = memo(
  ({ className, children, readOnly, size, onRemoveClick }) => (
    <div
      className={cx(
        styles.autcompleteTag,
        styles[`autocompleteTag${Formatter.ucfirst(size)}`],
        className
      )}
      role="button"
      tabIndex="-1"
      title={children}
    >
      <span className={styles.autocompleteLabel}>{children}</span>
      {!readOnly && (
        <Icon name="close" className="cu-p" onClick={onRemoveClick} />
      )}
    </div>
  )
)

ChipCustom.propTypes = {
  size: string.isRequired,
  children: string,
  className: string,
  readOnly: bool,
  onRemoveClick: func
}

ChipCustom.defaultProps = {
  children: null,
  className: '',
  readOnly: true,
  onRemoveClick: () => null
}

export default ChipCustom

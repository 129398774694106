import React, { Component } from 'react'
import { func, number, string, any, bool } from 'prop-types'
import cx from 'classnames'

// components
import Button from '@components/Button'
import Icon from '@components/Icon'

// styles
import styles from './_.module.scss'

const PageStepButton = props => (
  <Button
    id={props.id}
    type="button"
    className={cx('di-f', props.className)}
    palette={props.step === props.currentPage ? 'primary' : 'white'}
    onClick={() => props.onClick(props.step, props.currentPage)}
    disabled={props.isDisable}
  >
    {props.children}
  </Button>
)

PageStepButton.propTypes = {
  id: string,
  children: any,
  className: string,
  currentPage: number,
  isDisable: bool,
  step: number,
  onClick: func
}

PageStepButton.defaultProps = {
  id: null,
  children: null,
  step: 1,
  currentPage: 1,
  onClick: () => null,
  className: '',
  isDisable: false
}

const ControlButton = props => (
  <Button type="button" palette="white" {...props}>
    {props.children}
  </Button>
)

ControlButton.propTypes = {
  children: any,
  className: string
}

ControlButton.defaultProps = {
  children: null,
  className: ''
}

export function calculatePageSteps(
  minElementsToShow = 3,
  currentPage = 1,
  totalPages = 1
) {
  const steps = [currentPage, currentPage - 1 + minElementsToShow]

  if (currentPage > totalPages - (minElementsToShow - 1)) {
    const minSteps = totalPages - minElementsToShow + 1
    steps[0] = minSteps < 1 ? 1 : minSteps
    steps[1] = totalPages
  }

  return steps
}

function generatePageSteps(
  currentPage = 1,
  totalPages = 10,
  onClick = () => null,
  prefix = ''
) {
  const steps = calculatePageSteps(3, currentPage, totalPages)
  let counter = steps[0]
  const pageSteps = []

  do {
    pageSteps.push(
      <PageStepButton
        key={counter}
        step={counter}
        currentPage={currentPage}
        onClick={onClick}
        className={`${QA_PREFIX}__action--jump-${counter}`}
        isDisable={totalPages <= 1}
        id={`${prefix}paginator-page-${counter}`}
      >
        {counter}
      </PageStepButton>
    )
    counter++
  } while (counter <= steps[1])

  return pageSteps
}

const onClickHandler = (isDisabled, successCallback) => evt =>
  !isDisabled && successCallback(evt)

// qa
const QA_PREFIX = 'qa-pagination-nav'

export default class Nav extends Component {
  render() {
    const {
      currentPage,
      totalPages,
      onClickNext,
      onClickPrev,
      onClickStep,
      className,
      prefix
    } = this.props
    const isPrevDisabled = currentPage <= 1
    const isNextDisabled = currentPage >= totalPages

    return (
      <div
        className={cx(
          'di-f',
          'juco-sb',
          `qa-pagination-nav`,
          styles.nav,
          className
        )}
      >
        <ControlButton
          id={`${prefix}paginator-prev`}
          onClick={onClickHandler(isPrevDisabled, onClickPrev)}
          disabled={isPrevDisabled}
          className={`${QA_PREFIX}__action--prev`}
        >
          <Icon name="chevron-left" className="mar-2" />
          Prev
        </ControlButton>

        {generatePageSteps(currentPage, totalPages, onClickStep, prefix)}

        <ControlButton
          id={`${prefix}paginator-next`}
          onClick={onClickHandler(isNextDisabled, onClickNext)}
          disabled={isNextDisabled}
          className={`${QA_PREFIX}__action--next`}
        >
          Next
          <Icon name="chevron-right" className="mal-2" />
        </ControlButton>
      </div>
    )
  }
}

Nav.propTypes = {
  className: string,
  prefix: string,
  currentPage: number,
  totalPages: number,
  onClickNext: func,
  onClickPrev: func,
  onClickStep: func
}

Nav.defaultProps = {
  className: '',
  prefix: '',
  currentPage: 1,
  onClickNext: () => null,
  onClickPrev: () => null,
  qaPrefix: '',
  totalPages: 3,
  onClickStep: () => null
}

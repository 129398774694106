import React, {
  useReducer,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState,
  memo
} from 'react'
import { string, number, func, bool, oneOfType, node, oneOf } from 'prop-types'
import cx from 'classnames'

// components
import ConfirmationModal from '@components/ConfirmationModal'
import AutocompleteWrapper from './components/AutocompleteWrapper'
import DropdownAutocomplete from './components/DropdownAutocomplete'
import ActiveChips from './components/ActiveChips'
import Actions from './components/Actions'
import DropdownActions from './components/DropdownActions'
import Message from './components/Message'

// styles
import styles from './_.module.scss'

import {
  initialState,
  // accesors
  STATE_EDIT_MODE,
  STATE_EDIT_CANCEL,
  STATE_EDIT_SAVING,
  STATE_ADD_SELECTED,
  STATE_REMOVE_SELECTED,
  STATE_INPUT_FOCUS,
  STATE_VALUE,
  STATE_EDIT_COMPLETED,
  STATE_OFFSET_HEIGHT,
  STATE_MODAL_BLOCKER,
  // Dropdown status
  DROPDOWN_OPEN,
  // Mode
  MODE_READONLY,
  // sizes
  DEFAULT_SIZE,
  SMALL,
  // Shared PropTypes
  OptionsPropType
} from './modules/constants'
import { reducer, init, normalizeData } from './modules/helpers'

const Autocomplete = memo(
  ({
    className,
    label,
    placeholder,
    name,
    options,
    selected: preselectedOptions,
    required,
    validationError: inputValidationError,
    limitTags,
    readOnly: readOnlyForce,
    open: startOpen,
    border,
    isSelect,
    hideChipsOnEdit,
    autoSaveOnSelect,
    size,
    actionsBuilder,
    onChange,
    onSave,
    onCancel
  }) => {
    const [state, dispatch] = useReducer(
      reducer,
      initialState,
      init(inputValidationError, preselectedOptions)
    )
    const {
      mode,
      dropdownStatus,
      saved,
      selected,
      inputFocus: focus,
      validationError,
      loading,
      offsetHeight,
      unSavedChanges,
      blockCloseModal,
      value
    } = state
    const hasSelected = selected.size > 0
    const readOnlyMode = mode === MODE_READONLY
    const isDropdownOpen = dropdownStatus === DROPDOWN_OPEN
    let selectedArray = hasSelected ? Array.from(selected.values()) : []
    const remainings = []

    if (hasSelected && readOnlyMode) {
      selectedArray = selectedArray.filter((item, index) => {
        const filter = index < limitTags
        if (!filter) {
          remainings.push(item.value)
        }
        return filter
      })
    } else {
      selectedArray = selectedArray.map(item => ({
        ...item,
        readOnly: options.findIndex(opt => opt.id === item.id) === -1
      }))
    }

    const isEmpty = !(hasSelected || focus || `${value || ''}`.length > 0)
    const autocomplete = useRef(null)
    const textInput = useRef(null)

    const [handleApiResponse, setHandleApiResponse] = useState(() => () => null)

    const memoValue = useMemo(() => `${(value || '').trim().toLowerCase()}`, [
      value
    ])
    const memoOptions = useMemo(() => {
      if (!memoValue) return options
      return options.filter(
        item =>
          item.value
            .toString()
            .toLowerCase()
            .indexOf(memoValue) !== -1
      )
    }, [memoValue, options])

    useEffect(() => {
      if (startOpen) {
        dispatch({ type: STATE_EDIT_MODE, payload: { offsetHeight } })
      }
    }, [offsetHeight, startOpen])
    useEffect(() => {
      if (!readOnlyMode) {
        const offsetHeight =
          autocomplete && autocomplete.current
            ? autocomplete.current.offsetHeight
            : 0
        setTimeout(() => {
          dispatch({ type: STATE_OFFSET_HEIGHT, payload: offsetHeight })
        }, 0)
      }
    }, [readOnlyMode])
    useEffect(() => {
      setHandleApiResponse(
        () =>
          function handleApiResponse(err, response) {
            const { data } = response || {}
            const action = {
              type: STATE_EDIT_COMPLETED,
              payload: {
                validationError: null,
                saved: null
              }
            }
            if (err) {
              action.payload.validationError =
                err.message || 'Error saving your changes.'
            } else {
              const saved = normalizeData(data)
              action.payload.saved = saved
            }
            dispatch(action)
          }
      )
      return () => {
        setHandleApiResponse(() => () => null)
      }
    }, [])

    const handleEditMode = useMemo(() => {
      return function(event) {
        const offsetHeight = autocomplete.current.offsetHeight
        if (readOnlyMode && event.target.nodeName !== 'INPUT') {
          textInput.current.focus()
          return dispatch({ type: STATE_EDIT_MODE, payload: { offsetHeight } })
        }
        const { nodeName, className } = event.target
        if (
          nodeName === 'BUTTON' ||
          (nodeName === 'I' && className.indexOf('icon-chevron-') !== -1)
        ) {
          textInput.current.focus()
          return dispatch({
            type: STATE_INPUT_FOCUS,
            payload: { focus: !isDropdownOpen, offsetHeight }
          })
        }
      }
    }, [readOnlyMode, isDropdownOpen])

    const memoInputAction = useMemo(() => {
      const action = {
        type: STATE_INPUT_FOCUS,
        payload: {
          focus: isDropdownOpen ? !focus : true
        }
      }
      return action
    }, [focus, isDropdownOpen])

    const handleInputClick = useMemo(() => {
      return () => {
        const offsetHeight = autocomplete.current.offsetHeight
        memoInputAction.payload.offsetHeight = offsetHeight
        dispatch(memoInputAction)
      }
    }, [memoInputAction])

    const handleCancel = useCallback(() => {
      dispatch({ type: STATE_EDIT_CANCEL })
      onCancel()
    }, [onCancel])

    const memoShouldUpdate = useMemo(() => {
      let match = 0
      let shouldUpdate = true
      saved.forEach(item => (match += selected.has(item.id)))
      if (match === selected.size && selected.size === saved.size) {
        shouldUpdate = false
      }
      return shouldUpdate
    }, [saved, selected])

    const handleSave = useCallback(() => {
      const selectedArray = Array.from(selected.values())
      if (memoShouldUpdate) {
        dispatch({ type: STATE_EDIT_SAVING })
        return onSave(selectedArray, handleApiResponse)
      }
      if (autoSaveOnSelect) {
        return onSave(selectedArray, () => {
          dispatch({
            type: STATE_EDIT_COMPLETED,
            payload: {
              validationError: null,
              saved: selected
            }
          })
        })
      }
    }, [
      memoShouldUpdate,
      autoSaveOnSelect,
      selected,
      onSave,
      handleApiResponse
    ])

    const handleChipClick = useCallback(
      item => {
        onChange(item)
        if (!readOnlyMode && !loading) {
          dispatch({ type: STATE_REMOVE_SELECTED, payload: { id: item.id } })
        }
      },
      [onChange, readOnlyMode, loading]
    )

    const handleDropdownItemClick = (item, exists = false) => {
      let type = STATE_ADD_SELECTED
      const payload = {
        id: item.id
      }
      if (exists) {
        type = STATE_REMOVE_SELECTED
      } else {
        payload.value = item
      }
      dispatch({
        type,
        payload
      })
      onChange(item)
      if (autoSaveOnSelect) {
        const selectedArray = Array.from(selected.values()).filter(filterItem => {
          if (filterItem.id === item.id && type === STATE_REMOVE_SELECTED) {
            return false
          }
          return true
        })
        if (type === STATE_ADD_SELECTED) {
          selectedArray.push(item)
        }
        return onSave(selectedArray, handleApiResponse)
      }
      textInput.current.focus()
    }

    const handleInputChange = event => {
      const value = event.target.value || ''
      dispatch({ type: STATE_VALUE, payload: value })
    }
    const handleClickOutside = useCallback(() => {
      if (!isSelect) return handleCancel()
      if (autoSaveOnSelect) {
        return handleSave()
      }
      if (unSavedChanges && !blockCloseModal) {
        dispatch({ type: STATE_MODAL_BLOCKER, payload: true })
      } else if (!blockCloseModal) {
        handleCancel()
      }
    }, [
      isSelect,
      handleCancel,
      autoSaveOnSelect,
      unSavedChanges,
      blockCloseModal,
      handleSave
    ])

    return (
      <>
        <AutocompleteWrapper
          className={styles.fullWidth}
          listeningClickOutside={!readOnlyMode}
          onEventOutside={handleClickOutside}
        >
          <div
            className={cx(
              className,
              'po-r',
              styles[mode],
              styles.fullWidth,
              styles.container,
              {
                [styles.autocompleteSmall]: size === SMALL,
                [styles.empty]: isEmpty,
                [styles.notEmpty]: !isEmpty,
                [styles.loading]: loading,
                [styles.noLabel]: !label
              }
            )}
          >
            <div className="po-r" ref={autocomplete}>
              {label && (
                <label
                  className={cx(styles.autocompleteLabel)}
                  id={`${name}-multiple-select`}
                >
                  {label}
                </label>
              )}
              <div
                className={cx(
                  'di-if',
                  'alit-c',
                  'pa-1',
                  'po-r',
                  styles.autocomplete,
                  styles.fullWidth,
                  { [styles.noIcon]: actionsBuilder === false }
                )}
                onClick={event => !readOnlyForce && handleEditMode(event)}
              >
                <ActiveChips
                  selectedArray={selectedArray}
                  size={size}
                  readOnly={loading || readOnlyMode || readOnlyForce}
                  onChipClick={handleChipClick}
                />
                {readOnlyMode && remainings.length > 0 ? (
                  <span className={styles.tag} title={remainings.join(',')}>
                    + {remainings.length}
                  </span>
                ) : (
                  ''
                )}
                <input
                  ref={textInput}
                  type="text"
                  name={name}
                  className={
                    readOnlyMode && selected.size > 0 ? 'di-n' : 'di-b'
                  }
                  aria-autocomplete="list"
                  autoComplete="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoFocus={true}
                  onClick={event =>
                    !loading && !readOnlyForce && handleInputClick(event)
                  }
                  onChange={handleInputChange}
                  value={value}
                  readOnly={loading || readOnlyForce}
                  disabled={readOnlyForce}
                  required={required}
                  placeholder={focus || !label ? placeholder : ''}
                />
                {actionsBuilder !== false && (
                  <DropdownActions
                    loading={loading}
                    isEditMode={!readOnlyMode}
                    readOnly={readOnlyForce}
                    isDropdownOpen={isDropdownOpen}
                    actionsBuilder={actionsBuilder}
                  />
                )}
                <fieldset
                  aria-hidden="true"
                  className={cx({
                    [styles.error]: Boolean(validationError),
                    [styles.border]: border
                  })}
                >
                  {label && (
                    <legend>
                      <span>{label}</span>
                    </legend>
                  )}
                </fieldset>
                <Message
                  show={!!validationError}
                  message={validationError || ''}
                  className={styles.error}
                />
              </div>
            </div>
            {isSelect && (
              <>
                <DropdownAutocomplete
                  open={isDropdownOpen && !readOnlyForce}
                  name={name}
                  options={memoOptions}
                  selectedMap={selected}
                  offsetHeight={offsetHeight}
                  onItemClick={handleDropdownItemClick}
                />
                {!autoSaveOnSelect && (
                  <Actions
                    editMode={!readOnlyMode}
                    showCancelOnly={readOnlyForce}
                    dropdownClosed={!isDropdownOpen}
                    className={styles.buttons}
                    disabled={loading}
                    cancelText={readOnlyForce ? 'Close' : 'Cancel'}
                    onSave={handleSave}
                    onCancel={handleCancel}
                  />
                )}
              </>
            )}
          </div>
        </AutocompleteWrapper>
        {blockCloseModal && (
          <ConfirmationModal
            onBackdropClick={() => null}
            onClose={handleCancel}
            className={styles.unSavedChanges}
            headerTex="Are you sure?"
            message="You have unsaved changes. Did you mean to leave without saving?"
            okText="Save Changes"
            cancelText="Don't Save"
            onOk={handleSave}
          />
        )}
      </>
    )
  }
)

Autocomplete.propTypes = {
  className: string,
  label: string,
  placeholder: string,
  size: oneOf([DEFAULT_SIZE, SMALL]),
  name: string,
  options: OptionsPropType,
  selected: OptionsPropType,
  limitTags: number,
  validationError: string,
  required: bool,
  readOnly: bool,
  border: bool,
  open: bool,
  isSelect: bool,
  hideChipsOnEdit: bool,
  autoSaveOnSelect: bool,
  actionsBuilder: oneOfType([func, bool, node]),
  onChange: func,
  onSave: func,
  onCancel: func
}

Autocomplete.defaultProps = {
  className: '',
  label: '',
  size: DEFAULT_SIZE,
  placeholder: '',
  name: '__name__',
  options: [],
  selected: [],
  limitTags: 2,
  validationError: '',
  required: false,
  border: true,
  readOnly: false,
  open: false,
  isSelect: true,
  hideChipsOnEdit: false,
  autoSaveOnSelect: false,
  actionsBuilder: null,
  onChange: () => null,
  onSave: () => null,
  onCancel: () => null
}

export default Autocomplete

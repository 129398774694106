import React from 'react'
import { string, any, bool } from 'prop-types'

// components
import FieldGroup from '../FieldGroup'
import TextFieldGroup from '../TextFieldGroup'
import EmailFieldGroup from '../EmailFieldGroup'
import PhoneFieldGroup from '../PhoneFieldGroup '
import TextAreaFieldGroup from '../TextAreaFieldGroup'
import SelectFieldGroup from '../SelectFieldGroup'
import CheckboxFieldGroup from '../CheckboxFieldGroup'

// modules
import {
  TEXT_FIELD,
  EMAIL_FIELD,
  PHONE_FIELD,
  TEXTAREA_FIELD,
  SELECT_FIELD,
  CHECKBOX_FIELD,
  GENERIC_FIELD
} from '../../modules/constants'

const ComponentsMap = {
  [TEXT_FIELD]: TextFieldGroup,
  [EMAIL_FIELD]: EmailFieldGroup,
  [PHONE_FIELD]: PhoneFieldGroup,
  [TEXTAREA_FIELD]: TextAreaFieldGroup,
  [SELECT_FIELD]: SelectFieldGroup,
  [CHECKBOX_FIELD]: CheckboxFieldGroup,
  [GENERIC_FIELD]: props => <input {...props} />
}

const PolyFieldGroup = ({
  componentName,
  defaultValue,
  name,
  label,
  dataSource,
  isRequired,
  errorMessage,
  ...props
}) => {
  const WrapperComponent =
    ComponentsMap[componentName] || ComponentsMap[GENERIC_FIELD]
  if (componentName === SELECT_FIELD) {
    props.dataSource = dataSource
  }
  return (
    <FieldGroup>
      {componentName !== CHECKBOX_FIELD && (
        <label htmlFor={name}>
          {label}
          {isRequired && <span className="mal-1 co-orange-3">*</span>}
        </label>
      )}
      <WrapperComponent
        name={name}
        label={label}
        defaultValue={defaultValue}
        errorMessage={errorMessage}
        {...props}
      />
    </FieldGroup>
  )
}
PolyFieldGroup.propTypes = {
  componentName: string.isRequired,
  dataSource: any.isRequired,
  defaultValue: any.isRequired,
  label: any.isRequired,
  name: any.isRequired,
  errorMessage: any,
  isRequired: bool
}

PolyFieldGroup.defaultProps = {
  errorMessage: null,
  isRequired: false
}

export default PolyFieldGroup

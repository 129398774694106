import React, { useState } from 'react'
import { array, func, number, string, object, bool } from 'prop-types'
import cx from 'classnames'

// components
import { ToastNotify } from '@compositions/Toast'
import Checkbox from '@components/Checkbox'
import ConfirmationModal from '../ConfirmationModal'
import NoResults from '../NoResults'
import SyntheticColumnHeaderSet from '../SyntheticColumnHeaderSet'
import SyntheticColumnHeader from '../SyntheticColumnHeader'
import TableTr from '../TableTr'
import ChangeRolesModal from '../ChangeUserRoles'
import ConfirmInviteUser from '../ConfirmInviteUser'

// modules
import { Resource } from '@modules/rest-api'
import {
  REST_API_ENDPOINTS,
  API_VERSION
} from '@modules/constants'
import { SORT_ASC, SORTBY_NAME, COLUMNS } from '../../modules/constants'

// styles
import styles from './_.module.scss'

const classes = {
  ...styles,
  wrapper: 'di-f'
}

const Table = ({
  currentUserId,
  users,
  bulkUpdating,
  selected,
  className,
  onClickCheckbox,
  onUserUpdated,
  availableRoles,
  ...rest
}) => {
  const [userToUpdate, setUserToUpdate] = useState(null)
  const [inviteUser, setInviteUser] = useState(null)
  const [changeUserRoles, setChangeUserRoles] = useState(null)

  const allSelected = (users.length - 1) === selected.size

  return (
    <div
      className={cx(
        classes.tableContainer,
        className,
        'mal-a',
        'mar-a',
        'qa-table'
      )}
      role="table"
    >
      <div
        className={cx(
          'di-f',
          'fldi-r',
          'flwr-nw',
          'pay-2',
          classes.tableRow,
          'qa-table-headers'
        )}
        role="row"
      >
        <div className={cx(classes.wrapper, classes.attributes)}>
          <div className={cx(bulkUpdating ? 'di-f' : 'di-n', classes.idCheckbox)}>
            <SyntheticColumnHeader column={COLUMNS[0]}>
              <Checkbox.Controlled
                id="users-checkbox-selectall"
                onClick={() => onClickCheckbox(null, null, true)}
                checked={allSelected}
              />
            </SyntheticColumnHeader>
          </div>
          <SyntheticColumnHeaderSet
            items={COLUMNS.slice(1, 3)}
            wrapperClassName={classes.nameEmail}
            {...rest}
          />
          <SyntheticColumnHeaderSet
            items={COLUMNS.slice(3, 5)}
            wrapperClassName={classes.statusLastLogin}
            {...rest}
          />
        </div>

        <div
          className={cx(classes.wrapper, classes.attributes, classes.actions)}
        >
          <SyntheticColumnHeaderSet
            items={COLUMNS.slice(5)}
            wrapperClassName={classes.accessLevelActionReset}
            {...rest}
          />
        </div>
      </div>

      {users.length > 0 ? (
        users.map(user => {
          const {
            id,
            firstName,
            lastName,
            email,
            status,
            lastLogin,
            isBlocked,
            roles
          } = user
          const key = `${id}-${email}`
          const checked = selected.has(id)
          return (
            <TableTr
              key={key}
              id={id}
              currentUserId={currentUserId}
              firstName={firstName}
              lastName={lastName}
              isBlocked={isBlocked}
              email={email}
              status={status}
              lastLogin={lastLogin}
              userRoles={roles || []}
              roles={roles || []}
              onRolesChange={() => setChangeUserRoles(user)}
              onInvite={() => setInviteUser(user)}
              onStatusChange={() => setUserToUpdate(user)}
              checked={checked}
              bulkUpdating={bulkUpdating}
              onCheckboxClick={() => onClickCheckbox(user, checked)}
            />
          )
        })
      ) : (
        <NoResults />
      )}

      {inviteUser && (
        <ConfirmInviteUser
          user={inviteUser}
          onClose={updatedUser => {
            setInviteUser(null)
            onUserUpdated(updatedUser)
          }}
        />
      )}
      {changeUserRoles && (
        <ChangeRolesModal
          user={changeUserRoles}
          currentUserId={currentUserId}
          roles={availableRoles}
          onClose={updatedUser => {
            setChangeUserRoles(null)
            onUserUpdated(updatedUser)
          }}
        />
      )}
      {userToUpdate && (
        <ConfirmationModal
          isBlocked={userToUpdate.isBlocked}
          closeModal={() => {
            setUserToUpdate(null)
          }}
          onConfirm={() => {
            const id = userToUpdate.id.replace('auth0|', '')
            const resource = new Resource(REST_API_ENDPOINTS.users, API_VERSION)
            const config = {
              data: {
                isBlocked: !userToUpdate.isBlocked
              }
            }
            resource.putSigned(id, config, (err, res = {}) => {
              setUserToUpdate(null)
              if (err) {
                const { response } = err || {}
                const { status } = response || {}
                if (status !== 403) {
                  ToastNotify.error(
                    'There was an error saving your change. Please try again later.'
                  )
                }
              } else {
                const data = res.data || {}
                const results = data.results || []
                onUserUpdated(results)
                ToastNotify.success(
                  `The selected employee has been ${
                    results.isBlocked ? 'disabled' : 'enabled'
                  }.`
                )
              }
            })
          }}
        />
      )}
    </div>
  )
}

Table.propTypes = {
  currentUserId: string.isRequired,
  className: string,
  availableRoles: array,
  users: array,
  sortColumn: number,
  sortOrder: number,
  bulkUpdating: bool,
  selected: object,
  onSort: func,
  onUserUpdated: func,
  onClickCheckbox: func
}

Table.defaultProps = {
  availableRoles: [],
  users: [],
  sortOrder: SORT_ASC,
  sortColumn: SORTBY_NAME,
  className: '',
  bulkUpdating: false,
  selected: new Map(),
  onSort: () => null,
  onUserUpdated: () => null,
  onClickCheckbox: () => null
}

export default Table

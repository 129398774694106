import React, { Component } from 'react'
import { func, number, string } from 'prop-types'
import cx from 'classnames'

import Nav from '../Nav'

// qa
const QA_PREFIX = 'qa-paginator'

export default class Paginator extends Component {
  render() {
    const { className, ...rest } = this.props

    return (
      <div className={cx('di-f', 'juco-sb', 'pax-2', QA_PREFIX, className)}>
        <Nav {...rest} className="alse-fs" />
      </div>
    )
  }
}

Paginator.propTypes = {
  className: string,
  currentPage: number,
  qaPrefix: string,
  totalPages: number,
  onClickNext: func,
  onClickPrev: func,
  onClickStep: func
}

Paginator.defaultProps = {
  currentPage: 1,
  onClickNext: () => null,
  onClickPrev: () => null,
  qaPrefix: '',
  totalPages: 3,
  onClickStep: () => null,
  className: ''
}

import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { func, object, shape, bool, array, any } from 'prop-types'

// components
import { ToastNotify } from '@compositions/Toast'
import Form from '@components/Form'
import Modal, { SMALL_MODAL } from '@components/Modal'
import MediaObject from '@components/MediaObject'
import FeatureToggle from '@components/FeatureToggle'
import EditControls from '@components/EditControls'
import PolyFieldGroup from './components/PolyFieldGroup'
import LoadingMessage from './components/LoadingMessage'

// styles
import styles from './_.module.scss'

// modules
import { Resource } from '@modules/rest-api'
import { REST_API_ENDPOINTS } from '@modules/constants'
import { sanitizeModel, validateSchema } from './modules/helpers'
import {
  FORM_FIELDS,
  COUNTRY_CODES,
  FORM_CREATE_FIELDS,
  FORM_UPDATE_FIELDS
} from './modules/constants'

const ROLE_DATA_SOURCE = 'roleId'
const JOBTITLEID_DATA_SOURCE = 'jobTitleId'
const COMMUNITIES_DATA_SOURCE = 'community'
const COUNTRY_DATA_SOURCE = 'countryCode'
const FEATURE_FLAGS_ACTIONS = {
  false: 'employeeEdit',
  true: 'employeeCreate'
}

const EditEmployeeForm = ({
  employee,
  rolesDataSource,
  communitiesDataSource,
  closeModal,
  isNew,
  withCommunityField,
  errors,
  onFormSubmit
}) => {
  const [loadingMessage, setLoadingMessage] = useState(null)
  const [model, setModel] = useState({})
  const [isValid, setIsValid] = useState(!isNew)
  const [disabled, setDisabled] = useState(false)
  const [schema, setSchema] = useState(FORM_FIELDS)
  const [isPrimary, setIsPrimary] = useState(model.isPrimary || false)
  const [hasReferralAlertsLock, setHasReferralAlertsLock] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const dataSources = {
    [ROLE_DATA_SOURCE]: rolesDataSource,
    [JOBTITLEID_DATA_SOURCE]: rolesDataSource,
    [COMMUNITIES_DATA_SOURCE]: communitiesDataSource,
    [COUNTRY_DATA_SOURCE]: { isLoading: false, data: COUNTRY_CODES }
  }

  useEffect(() => {
    const schemaForm = FORM_FIELDS.filter(field => {
      if (withCommunityField) {
        const FIELDS = { ...FORM_CREATE_FIELDS, community: true }
        if (!FIELDS[field.name]) return false
      } else {
        if (isNew && !FORM_CREATE_FIELDS[field.name]) return false
        if (!isNew && !FORM_UPDATE_FIELDS[field.name]) return false
      }
      return true
    })

    setSchema(schemaForm)

    if (!isNew) {
      setDisabled(true)
      setModel(sanitizeModel(schemaForm, employee))
      setLoadingMessage('Fetching full employee information...')

      const resource = new Resource(REST_API_ENDPOINTS.personnel)
      resource.readSigned(employee.employmentId, {}, (err, res) => {
        if (err) {
          setLoadingMessage(
            <>
              Something went wrong.
              <br />
              Please try again later.
            </>
          )
          setIsDataLoaded(false)
          console.error(err)
        } else {
          const { data } = res
          const { results } = data || {}
          setDisabled(false)
          setLoadingMessage(null)
          const incommingModel = sanitizeModel(schemaForm, results)
          setHasReferralAlertsLock(
            results.orgHasReferralAlertsLock !== undefined
              ? results.orgHasReferralAlertsLock
              : false
          )
          setModel(prevState => ({ ...prevState, ...incommingModel }))
          setIsPrimary(incommingModel.isPrimary)
          setIsDataLoaded(true)
        }
      })
    } else {
      setIsDataLoaded(true)
      setModel(sanitizeModel(schemaForm, {}))
    }
  }, [employee, isNew, withCommunityField])

  useEffect(() => {
    const isFormValid = validateSchema(schema, model)
    setIsValid(isFormValid)
  }, [schema, model])

  function getDataSource(name) {
    let datasource = {
      isLoading: true,
      error: null,
      data: []
    }
    const mainDataSource = dataSources[name] || {}
    if (
      mainDataSource.hasOwnProperty('isLoading') &&
      mainDataSource.hasOwnProperty('data')
    ) {
      datasource = mainDataSource
    }
    return datasource
  }

  const featureFlag = FEATURE_FLAGS_ACTIONS[isNew]

  return (
    <Modal
      className="po-r"
      size={SMALL_MODAL}
      footer={exit =>
        !loadingMessage &&
        !disabled && (
          <FeatureToggle name={featureFlag}>
            <EditControls
              disabled={disabled || !isValid}
              onCancel={exit}
              onSave={() => {
                setDisabled(true)
                onFormSubmit({
                  isPrimary,
                  model,
                  callback: (success, networkIssues) => {
                    setDisabled(false)
                    if (success) {
                      exit()
                      ToastNotify.success(
                        isNew
                          ? 'Your request has been sent to the Customer Service team.'
                          : 'Employee information updated'
                      )
                    } else {
                      if (networkIssues !== 403) {
                        ToastNotify.error(
                          networkIssues
                            ? 'No internet connection'
                            : `There was an error attempting to ${
                                isNew ? 'request' : 'update'
                              } the account. Please try again later or email partners@aplaceformom.com.`
                        )
                      }
                    }
                  }
                })
              }}
            />
          </FeatureToggle>
        )
      }
      onToggleActive={() => closeModal()}
    >
      {loadingMessage ? (
        <LoadingMessage message={loadingMessage} />
      ) : (
        <>
          {isNew ? (
            <>
              <h3 className="fowe-b mab-2">Request New User</h3>
              <p>
                You may also call us at{' '}
                <a href="tel:866-892-1205">866-892-1205</a> or email us at
                <br />
                <a href="mailto:partnercentral@aplaceformom.com">
                  partnercentral@aplaceformom.com
                </a>
              </p>
            </>
          ) : (
            <FeatureToggle name="employeePhoto">
              <MediaObject className={styles.avatarContainer} margin="mab-4">
                <MediaObject.Sidebar width="auto">
                  <div
                    className="baco-gray-2 bora-round"
                    style={{ width: 60, height: 60 }}
                  />
                </MediaObject.Sidebar>
                <MediaObject.Content
                  className="di-f juco-s alit-c mal-2"
                  style={{ height: 60 }}
                >
                  <div>Upload photo</div>
                </MediaObject.Content>
              </MediaObject>
            </FeatureToggle>
          )}
          {isDataLoaded && (
            <Form className={cx('di-f', 'fldi-c', 'mat-3', styles.form)}>
              {schema.map((field, index) => {
                const readOnlyDisabled =
                  (isPrimary && field.name === 'isPrimary') || false
                return (
                  <PolyFieldGroup
                    key={index}
                    componentName={field.component}
                    name={field.name}
                    label={field.label}
                    defaultValue={model[field.name] || ''}
                    disabled={
                      disabled ||
                      readOnlyDisabled ||
                      (field.canReferralAlertLockThis && hasReferralAlertsLock)
                    }
                    onChange={value => {
                      setModel(prevState => ({
                        ...prevState,
                        [field.name]: value
                      }))
                    }}
                    readOnly={readOnlyDisabled}
                    dataSource={getDataSource(field.name)}
                    errorMessage={errors[field.name]}
                    isRequired={field.isRequired}
                  />
                )
              })}
            </Form>
          )}
          {!isNew && hasReferralAlertsLock && (
            <span className="fosi-1 co-gray-3 pal-4 mal-2 di-b">
              You are not authorized to change this setting.
              <br />
              Please contact your organization's administrator
              <br />
              for requested changes.
            </span>
          )}
          {disabled &&
            (isNew ? (
              <LoadingMessage
                spacing={1}
                message="Sending invitation, please wait..."
              />
            ) : (
              <LoadingMessage
                spacing={1}
                message="Updating employee, please wait..."
              />
            ))}
        </>
      )}
    </Modal>
  )
}

EditEmployeeForm.propTypes = {
  employee: object,
  isNew: bool,
  withCommunityField: bool,
  rolesDataSource: shape({
    isLoading: bool.isRequired,
    data: array.isRequired,
    error: any
  }),
  communitiesDataSource: shape({
    isLoading: bool.isRequired,
    data: array.isRequired,
    error: any
  }),
  errors: object,
  closeModal: func,
  onFormSubmit: func
}

EditEmployeeForm.defaultProps = {
  employee: {},
  isNew: false,
  withCommunityField: false, // Flag to show the communities dropdown
  rolesDataSource: {
    isLoading: false,
    data: []
  },
  communitiesDataSource: {
    isLoading: false,
    data: []
  },
  errors: {},
  closeModal: () => null,
  onFormSubmit: () => null
}

export default EditEmployeeForm

import React from 'react'
import { string, func } from 'prop-types'
import cx from 'classnames'

import Icon from '@components/Icon'

const Action = ({ onClick, fontColor, iconName, cursor, label }) => {
  return (
    <span
      onClick={onClick}
      className={cx('di-f', 'wi-100', 'pay-2', fontColor, cursor, 'action')}
    >
      {iconName && <Icon className="mar-2" name={iconName} size={12} />}
      {label}
    </span>
  )
}

Action.propTypes = {
  fontColor: string,
  iconName: string,
  label: string,
  cursor: string,
  onClick: func
}

Action.defaultProps = {
  fontColor: '',
  iconName: '',
  label: '',
  cursor: 'cu-p',
  onClick: () => {}
}

export default Action

export const SORT_DESC = 0
export const SORT_ASC = 1

export const STATUS_ACTIVE = 0
export const STATUS_DISABLED = 1
export const STATUS_INVITED = 2
export const STATUS_NOT_INVITED = 3

// accessors
export const STATE_DATA = 'data'
export const STATE_DATA_REQUEST_STATUS = 'dataRequestStatus'
export const STATE_DATA_REQUEST_ERROR = 'dataRequestError'
export const STATE_ERROR = 'error'

export const SORTBY_NAME = 0
export const SORTBY_EMAIL = 1
export const SORTBY_STATUS = 2
export const SORTBY_LAST_LOGIN = 3

export const COLUMNS = [
  {
    sortColumn: -1,
    text: ' ',
    classNamePointer: 'id',
    isFilter: false
  },
  {
    sortColumn: SORTBY_NAME,
    text: 'Name',
    classNamePointer: 'name',
    isFilter: true
  },
  {
    sortColumn: SORTBY_EMAIL,
    text: 'Email',
    classNamePointer: 'email',
    isFilter: true
  },
  {
    sortColumn: SORTBY_STATUS,
    text: 'Status',
    classNamePointer: 'status',
    isFilter: true
  },
  {
    sortColumn: SORTBY_LAST_LOGIN,
    text: 'Last Login',
    classNamePointer: 'lastLogin',
    isFilter: true
  },
  {
    sortColumn: SORTBY_LAST_LOGIN + 1,
    text: 'Roles',
    classNamePointer: 'accessLevel',
    isFilter: false
  },
  {
    sortColumn: SORTBY_LAST_LOGIN + 2,
    text: 'Disable/Enable',
    classNamePointer: 'action',
    isFilter: false
  },
  {
    sortColumn: SORTBY_LAST_LOGIN + 3,
    text: 'Reset',
    classNamePointer: 'resetPassword',
    isFilter: false
  }
]

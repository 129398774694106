import React, { useState, useEffect } from 'react'
import { any } from 'prop-types'

// modules
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'

export const SettingsContext = React.createContext()

const flattenBusinessUnits = data => {
  return data.reduce((acc, val) => {
    return val.nodes && val.nodes.length > 0
      ? acc.concat(flattenBusinessUnits(val.nodes))
      : acc.concat(val)
  }, [])
}

const getCommunities = data => {
  return data
    .filter(val => val.level === 4)
    .map(comm => ({ id: comm.id, value: comm.name }))
}

const ContextWrapper = ({ children }) => {
  const [addEmployee, setAddEmployee] = useState(false)
  const [errors, setErrors] = useState({})
  const [availableRoles, setAvailableRoles] = useState({
    isLoading: true,
    data: [],
    error: null
  })
  const [communities, setCommunities] = useState({
    isLoading: true,
    data: [],
    error: null
  })
  const [employeeRoles, setEmployeeRoles] = useState({
    isLoading: true,
    data: [],
    error: null
  })

  // Load roles data
  useEffect(() => {
    const rolesCollection = new Collection(
      REST_API_ENDPOINTS.roles,
      API_VERSION
    )
    rolesCollection.readSigned({}, (error, res) => {
      if (error) {
        return setEmployeeRoles(prevState => ({ ...prevState, error }))
      }
      const { data: response } = res || {}
      const data = (response && response.results) || []
      setEmployeeRoles(prevState => ({
        ...prevState,
        isLoading: false,
        data
      }))
    })
  }, [])

  // Load Available Roles
  useEffect(() => {
    const config = {
      apiVersion: API_VERSION
    }
    const rolesCollection = new Collection(REST_API_ENDPOINTS.availableRoles)
    rolesCollection.readSigned(config, (error, res) => {
      if (error) {
        return setAvailableRoles(prevState => ({ ...prevState, error }))
      }
      const { data: response } = res || {}
      const data = (response && response.results) || []
      setAvailableRoles(prevState => ({
        ...prevState,
        isLoading: false,
        data
      }))
    })
  }, [])

  // Load communities data
  useEffect(() => {
    const communitiesCollection = new Collection(
      REST_API_ENDPOINTS.businessUnits
    )
    communitiesCollection.readSigned({}, (error, res) => {
      if (error) {
        return setCommunities(prevState => ({ ...prevState, error }))
      }
      const { data: response } = res || {}
      const data = (response && response.results) || []

      setCommunities(prevState => ({
        ...prevState,
        isLoading: false,
        data: getCommunities(flattenBusinessUnits(data))
      }))
    })
  }, [])

  const contextValue = {
    addEmployee,
    setAddEmployee,
    errors,
    setErrors,
    employeeRoles,
    communities,
    availableRoles
  }

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  )
}

ContextWrapper.propTypes = {
  children: any
}

ContextWrapper.defaultProps = {
  children: null
}

export default ContextWrapper
